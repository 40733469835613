import {
	Button,
	CircularProgress,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { StyledDialog, StyledDialogActions } from './CreateTemplateDialog.styles';
import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../context/FormV2Context.types';
import { useTemplateContext } from './utils/useTemplateContext';
import { SnackContext } from '../../../../../../context/SnackProvider';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';

const CreateTemplateDialog = ({
	open,
	onClose,
}: {
	open: boolean;
	onClose: () => void;
}) => {
	const [templateName, setTemplateName] = useState('');
	const [isCheckingName, setIsCheckingName] = useState(false);
	const { utilityForm } = useContext(FormV2Context);
	const { addTemplate, isCreating, checkTemplateNameExists } = useTemplateContext(
		utilityForm?.id
	);
	const { setSnackbarProps } = useContext(SnackContext);

	useEffect(() => {
		if (open) {
			setTemplateName('');
			setIsCheckingName(false);
		}
	}, [open]);

	const handleAddTemplate = async () => {
		if (!templateName.trim()) return;

		setIsCheckingName(true);
		const nameExists = await checkTemplateNameExists(templateName.trim());
		setIsCheckingName(false);

		if (nameExists) {
			setSnackbarProps({
				open: true,
				message: `A template with the name '${templateName.trim()}' already exists.`,
				severity: 'error',
			});
			return;
		}

		await addTemplate({ name: templateName.trim() });
		setSnackbarProps({
			open: true,
			message: `Success! Template '${templateName.trim()}' has been created.`,
			severity: 'success',
		});
		onClose();
	};

	return (
		<StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Create Template from Form</DialogTitle>
			<DialogContent>
				{!isCreating ? (
					<>
						<TextField
							autoFocus
							margin="dense"
							id="template-name"
							label="Template Name"
							placeholder={'Enter new name'}
							type="text"
							fullWidth
							variant="standard"
							value={templateName}
							onChange={e => setTemplateName(e.target.value)}
							disabled={isCreating || isCheckingName}
						/>
						{isCheckingName && (
							<div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
								<CircularProgress size={20} style={{ marginRight: 8 }} />
								Ensuring no templates exist already with this name...
							</div>
						)}
					</>
				) : (
					<LoadingScreen size={20} message={'Creating Template'} textColor="black" />
				)}
			</DialogContent>
			<StyledDialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					onClick={handleAddTemplate}
					disabled={!templateName.trim() || isCheckingName || isCreating}>
					Add Template
				</Button>
			</StyledDialogActions>
		</StyledDialog>
	);
};

export default CreateTemplateDialog;
