import React from 'react';

import Loop from '@mui/icons-material/Loop';
import { StyledIconButton } from './components';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SwitchForm() {
	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		navigate(location.pathname.replace('capture-form-v3', 'capture-form-v2'));
	};

	return (
		<StyledIconButton onClick={handleClick} title="Tools">
			<Loop color="primary" />
		</StyledIconButton>
	);
}
