import { Select, MenuItem, styled } from '@mui/material';
import theme from '../../../styles/theme';

export const StyledSelect = styled(Select)<{ isViewMode?: boolean }>`
	background-color: rgb(18, 18, 18);

	&.Mui-focused fieldset,
	fieldset {
		border-color: #242424;
	}

	&:hover:not(.Mui-focused) {
		fieldset {
			border-color: #fff;
		}
	}

	&[aria-checked='true'] {
		background-color: 'blue';
	}

	.MuiSvgIcon-root {
		fill: #838383;
	}

	${({ isViewMode }) =>
		isViewMode &&
		`
    .MuiSelect-select {
      padding: 2px 4px;
      min-height: 20px !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.grey[800]};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.grey[800]};
    }

    .MuiSelect-icon {
      font-size: 1.2rem;
      right: 2px;
    }
  `}
`;

export const StyledMenuItem = styled(MenuItem)`
	color: #fbfbfb;
	background-color: rgb(18, 18, 18);
	min-height: auto;
	padding: 6px 12px;

	&.Mui-selected,
	&:hover {
		background-color: rgba(144, 202, 249, 0.16);
	}
`;

export const StyledMenuPaper = styled('div')`
	background-color: rgb(18, 18, 18);

	.MuiMenu-list {
		padding: 0;
	}
`;
