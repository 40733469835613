import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { numberStrToMoney, parseNumFromStr } from '../../../../../../../utils';
import StringTextField from '../StringTextField';

export default function SubTotalCell({
	row,
	rowEditing,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
}) {
	const projectId = row.original.id as string;
	const subTotal = row.original.subTotal;

	return !rowEditing ? (
		<span>{subTotal ? numberStrToMoney(`${parseNumFromStr(subTotal)}`) : '-'}</span>
	) : (
		<StringTextField projectId={projectId} defaultValue={subTotal} field="subTotal" />
	);
}
