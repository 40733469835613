import {
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { ReportData, ReportQuestions } from '../../../types/reports';
import { createPickerReport } from '../../../firebase';
import { ProjectDataWithForms, Team } from '../../../types';
import { UtilityFormV2 } from '../ProjectUtilityFormV2/utils/types';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthProvider';

type useReportsType = {
	teamId: string;
	organizationId: string;
	reportName: string;
};

const useReports = ({ organizationId, teamId, reportName }: useReportsType) => {
	const { user } = useContext(AuthContext);

	const [searchParams, setSearchParams] = useSearchParams();

	const [reports, setReports] = useState<ReportData[] | undefined>();
	const [loading, setLoading] = useState(true);
	const [selectedReportId, setSelectedReportId] = useState<string | undefined>();
	const [reportQuestions, setReportQuestions] = useState<ReportQuestions | undefined>();
	const [projectsWithForms, setProjectsWithForms] = useState<
		ProjectDataWithForms[] | undefined
	>();

	const totalSelectedForms =
		projectsWithForms?.reduce(
			(acc, project) => acc + project.forms.filter(form => form.report).length,
			0
		) || 0;

	useEffect(() => {
		if (!teamId || !organizationId) return;

		const ref = query(
			collection(getFirestore(), 'reports'),
			where('teamId', '==', teamId),
			where('organizationId', '==', organizationId),
			orderBy('createdAt', 'desc')
		);

		const unsubscribe = onSnapshot(ref, snapshot => {
			const reports = snapshot.docs.map(
				doc => ({ ...doc.data(), id: doc.id } as ReportData)
			);

			setReports(reports);
			setLoading(false);
		});

		return () => unsubscribe();
	}, [organizationId, teamId, user?.teamIds]);

	// Handles when the reportId search param is changed.
	useEffect(() => {
		const newReportId = searchParams.get('reportId');
		if (newReportId) {
			setSelectedReportId(newReportId);
		}
	}, [searchParams]);

	useEffect(() => {
		if (selectedReportId) loadQuestions(selectedReportId);
	}, [selectedReportId]);

	const loadQuestions = async (reportId: string) => {
		setReportQuestions(undefined);
		const ref = doc(getFirestore(), `reportQuestions/${reportId}`);
		const questionDoc = await getDoc(ref);
		if (questionDoc.exists()) {
			setReportQuestions(questionDoc.data() as ReportQuestions);
		}
	};
	const createReport = () => createPickerReport({ teamId, formName: reportName }); // Use reportName from the hook

	const loadForms = async () => {
		setProjectsWithForms(undefined);

		const team = (await getDoc(doc(getFirestore(), `teams/${teamId}`))).data() as Team;

		if (!team.projectIds || team.projectIds.length === 0) return;

		const projectsPromises = team.projectIds?.map(projectId =>
			getDoc(doc(getFirestore(), `projects/${projectId}`))
		);

		const projectsDocs = await Promise.all(projectsPromises);

		const projects = projectsDocs.map(
			doc => ({ ...doc.data(), id: doc.id } as ProjectDataWithForms)
		);
		const formPromises = projects.map(project =>
			getDocs(
				query(
					collection(getFirestore(), 'utility_forms_v2'),
					where('projectId', '==', project.id)
				)
			)
		);

		const formCollections = await Promise.all(formPromises);

		const forms = formCollections
			.flatMap(collection =>
				collection.docs.map(doc => ({ ...doc.data(), id: doc.id } as UtilityFormV2))
			)
			.sort((a, b) => a.formName.localeCompare(b.formName));

		const projectsWithForms = projects
			.map(project => ({
				...project,
				forms: forms.filter(form => form.projectId === project.id),
			}))
			.sort((a, b) => b.forms.length - a.forms.length);

		const projectsWithCompletedForms = projectsWithForms
			.filter(project => project.forms.map(form => form.status).includes('completed'))
			.sort((a, b) => a.address.localeCompare(b.address));

		const projectsWithoutCompletedForms = projectsWithForms
			.filter(
				project =>
					project.forms.length > 0 &&
					!project.forms.map(form => form.status).includes('completed')
			)
			.sort((a, b) => a.address.localeCompare(b.address));

		const projectsWithoutForms = projectsWithForms
			.filter(project => project.forms.length === 0)
			.sort((a, b) => a.address.localeCompare(b.address));

		setProjectsWithForms([
			...projectsWithCompletedForms,
			...projectsWithoutCompletedForms,
			...projectsWithoutForms,
		]);
	};

	const toggleForm = async (formId: string, value: boolean) => {
		setProjectsWithForms(current =>
			current?.map(project => ({
				...project,
				forms: project.forms.map(form =>
					form.id === formId ? { ...form, report: value } : form
				),
			}))
		);
		const ref = doc(getFirestore(), `utility_forms_v2/${formId}`);
		await updateDoc(ref, { report: value });
	};

	const selectReport = (report: ReportData) => {
		if (report.status === 'processing') return;
		setSearchParams(prev => ({
			...prev,
			reportId: report.id,
		}));
	};

	return {
		createReport,
		loading,
		loadForms,
		projectsWithForms,
		reports,
		reportQuestions,
		selectedReportId,
		selectReport,
		toggleForm,
		totalSelectedForms,
	};
};

export default useReports;
