import { FC, useContext } from 'react';
import { ListItem } from '../../reusable-components/List';
import {
	UtilityFormDetailsForV2,
	UtilityFormV2,
} from '../ProjectUtilityFormV2/utils/types';

import useArchiveForm from './useArchiveForm';
import useRenameForm from './useRenameForm';
import useMenuItems from './useMenuItems';

import InputDialog from '../../reusable-components/InputDialog';
import ConfirmDialog from '../TemplateList/ConfirmDialog';
import useEditForm from './useEditForm';
import { V2FormsContext } from '../../../context/V2FormsProvider';
import { Box, styled } from '@mui/system';

type TemplateListItemProps = {
	form: UtilityFormV2 | UtilityFormDetailsForV2;
};

const FormsListItem: FC<React.PropsWithChildren<TemplateListItemProps>> = ({
	form: genericForm,
}) => {
	const form = genericForm as UtilityFormV2;

	const { projectId } = useContext(V2FormsContext);

	const { closeRenameDialog, handleRenameForm, isSaving, renameDialogOpen, renameForm } =
		useRenameForm(form);

	const {
		archiveDialogOpen,
		archiveForm,
		closeArchiveDialog,
		handleArchiveForm,
		isArchiving,
	} = useArchiveForm(form);

	const { closeEditDialog, editDialogOpen, editForm, handleEditForm, isEditing } =
		useEditForm(form);

	const optionalEditForm = ['completed'].includes(form.status) ? editForm : undefined;

	const { created, menuItems, updated } = useMenuItems({
		form,
		renameForm,
		archiveForm,
		editForm: optionalEditForm,
	});

	const url = form.v2
		? `/projects/${projectId}/capture-form-v3/${form.id}`
		: `/projects/${projectId}/capture-form/${form.id}`;

	const statusValue = () => {
		if (form.requiredFields && form.requiredFields.length > 0) {
			return `${form.completedFields?.length}/${form.requiredFields?.length} items completed`;
		} else {
			return form.status || 'Unknown';
		}
	};

	return (
		<>
			<ListItem
				title={form.formName || 'Unnamed Template'}
				content={[
					{ key: 'Status', value: statusValue() },
					{
						key: 'Updated',
						value: updated,
					},
					{ key: 'Creator', value: form.createdBy?.name || 'Unknown' },
					{
						key: 'Created',
						value: created,
					},
				]}
				url={url}
				menuItems={form.v2 ? menuItems : []}
				loading={isSaving || isArchiving || isEditing}
				disabled={!['draft', 'completed'].includes(form.status)}
				status={form.status}
				child={form.v2 ? undefined : <Legacy>Legacy</Legacy>}
				completedFields={form.completedFields}
				requiredFields={form.requiredFields}
			/>
			<InputDialog
				handleClose={closeRenameDialog}
				handleSave={handleRenameForm}
				initialValue={form.formName}
				open={renameDialogOpen}
				label="New template name"
				title="Rename Template"
			/>
			<ConfirmDialog
				handleClose={closeArchiveDialog}
				handleConfirm={handleArchiveForm}
				open={archiveDialogOpen}
				title="Archive Form"
				label={`Are you sure you want to archive ${form.formName} form?`}
			/>
			<ConfirmDialog
				handleClose={closeEditDialog}
				handleConfirm={handleEditForm}
				open={editDialogOpen}
				title="Edit form"
				label={`Are you sure you want to edit the completed form ${form.formName}?`}
			/>
		</>
	);
};

const Legacy = styled(Box)`
	position: absolute;
	top: 0;
	right: 0;
	background-color: #ffb310;
	color: black;
	padding: 2px 14px;
	border-radius: 5px;
	font-size: 12px;
`;

export default FormsListItem;
