import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../../styles/theme';

const StyledTextField = styled(TextField)<{ isViewMode?: boolean }>`
	width: 100%;

	${theme.breakpoints.up('lg')} {
		min-width: 400px;
	}

	${({ isViewMode }) =>
		isViewMode &&
		`
    // Removes floating label effect
    .MuiInputLabel-root {
      display: none;
    }

    .MuiInputBase-root {
      padding: 0;
      min-height: 20px;
      height: auto;
    }

    .MuiOutlinedInput-input {
      padding: 2px 4px; 
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.grey[800]};
    }

    &:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.grey[800]};
    }
  `}

	input {
		color: ${({ theme }) => theme.palette.newText.primary};

		&::placeholder {
			line-height: 1.6;
		}
		&.Mui-disabled {
			-webkit-text-fill-color: ${({ theme }) => theme.palette.newText.primary};
		}
	}

	fieldset {
		border-color: ${({ theme }) => theme.palette.grey[800]};
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: ${({ theme }) => theme.palette.grey[800]};
			}
		}
	}

	.MuiFormHelperText-root {
		display: none;
	}
`;

export default StyledTextField;
