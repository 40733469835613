import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { Team } from '../../../types';
// import CreateReportDialog from '../../screen-components/TeamPage/CreateReportDialog';
import useReports from '../../screen-components/TeamPage/useReports';
// import styled from 'styled-components';
import { SnackContext } from '../../../context/SnackProvider';
import logoSrc from '../../../assets/circleLogo.png';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import CreateReportFirstDialog from '../../screen-components/TeamPage/CreateReportFirstDialog';
import CreateReportSecondDialog from '../../screen-components/TeamPage/CreateReportSecondDialog';
import { StyledDialogLogo } from '../../form-components/Dialog/Dialog.styles';
import { ExpandMoreIcon } from '../../../screens/ProjectsList/components/ClientProjects/styles';

export default function TeamSelectionDialog({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { teams } = useContext(UserHomeContext);
	const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
	const [createReportDialogOpen, setCreateReportDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);
	const teamId = selectedTeam ? selectedTeam.id : '';
	const [createReportSecondDialogOpen, setCreateReportSecondDialogOpen] = useState(false);
	const { loadTeam } = useContext(OrganizationsContext);
	const [reportName, setReportName] = useState('');
	const { createReport, loadForms, projectsWithForms, toggleForm, totalSelectedForms } =
		useReports({
			organizationId: selectedTeam?.organizationId ?? '',
			teamId,
			reportName,
		});

	const handleTeamSelection = (value: Team) => {
		if (!value.projectIds || value.projectIds.length < 1) {
			setSnackbarProps({
				open: true,
				message: `${value.name} has 0 projects. Please select a team with projects in order to create analytics.`,
				severity: 'error',
			});
			setSelectedTeam(null);
			return;
		} else {
			setSelectedTeam(value);
			setSnackbarProps({
				open: false,
			});
		}
	};

	const openCreateReportDialog = () => {
		loadForms();
		setOpen(!open);
		setCreateReportDialogOpen(!createReportDialogOpen);
	};

	const handleClose = () => {
		setOpen(!open);
		setSelectedTeam(null);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				maxWidth={false}
				PaperProps={{
					sx: {
						width: '639px',
						maxWidth: 'none',
						height: '299px',
					},
				}}>
				<DialogTitle sx={{ background: '#242424', color: '#F6F6F6' }}>
					<StyledDialogLogo>
						<img src={logoSrc} alt="" style={{ height: 24 }} />
					</StyledDialogLogo>
					Select Team for Analytics
				</DialogTitle>
				<DialogContent
					sx={{
						background: '#121212',
						display: 'grid',
						gap: '10px',
						alignItems: 'center',
					}}>
					<TextField
						select
						name="team"
						label="Team"
						value={selectedTeam?.id || ''}
						fullWidth
						variant="standard"
						required
						InputLabelProps={{
							shrink: true,
							style: { color: 'white' },
						}}
						InputProps={{
							style: { color: 'white' },
							classes: {
								underline: 'white-underline',
							},
						}}
						SelectProps={{
							IconComponent: () => <ExpandMoreIcon style={{ color: 'white' }} />,
						}}
						onChange={e => {
							const teamId = e.target.value as string;
							if (teamId) {
								const chosenTeam = teams.find(team => team.id === teamId);
								if (chosenTeam) {
									loadTeam(chosenTeam?.id);

									const selectedTeamObj: Team = {
										id: chosenTeam.id,
										name: chosenTeam.name,
										organizationId: chosenTeam.organizationId,
										projectIds: chosenTeam.projectIds,
										userIds: chosenTeam.userIds,
									};
									handleTeamSelection(selectedTeamObj);
								}
							}
						}}
						sx={{
							'& .MuiInput-underline:before': {
								borderBottomColor: 'white', // Default underline color
							},
							'& .MuiInput-underline:hover:before': {
								borderBottomColor: 'white', // Underline color on hover
							},
							'& .MuiInput-underline:after': {
								borderBottomColor: 'white', // Underline color when focused
							},
						}}>
						{teams.map(team => (
							<MenuItem value={team.id} key={team.id}>
								{team.name}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<DialogActions sx={{ background: '#242424' }}>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						onClick={openCreateReportDialog}
						autoFocus
						disabled={!selectedTeam || selectedTeam?.id === ''}>
						Continue
					</Button>
				</DialogActions>
			</Dialog>

			<CreateReportFirstDialog
				open={createReportDialogOpen}
				projects={projectsWithForms}
				setOpen={setCreateReportDialogOpen}
				toggleForm={toggleForm}
				setSecondDialogOpen={setCreateReportSecondDialogOpen}
				totalSelectedForms={totalSelectedForms}
				setReportName={setReportName}
			/>

			<CreateReportSecondDialog
				open={createReportSecondDialogOpen}
				reportName={reportName}
				createReport={createReport}
				projects={projectsWithForms}
				setOpen={setCreateReportSecondDialogOpen}
				toggleForm={toggleForm}
				totalSelectedForms={totalSelectedForms}
			/>
		</>
	);
}

// const StyledActions = styled(DialogActions)`
// 	text-decoration: none;
// `;

// const ActionButton = styled(Button)`
// 	text-transform: none;
// `;
