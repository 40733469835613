import { FC } from 'react';
import { ListItem } from '../../reusable-components/List';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';

import InputDialog from '../../reusable-components/InputDialog';
import useDeleteTemplate from './useDeleteTemplate';
import useRenameTemplate from './useRenameTemplate';
import useMenuItems from './useMenuItems';
import ConfirmDialog from './ConfirmDialog';
import useDuplicateTemplate from './useDuplicateTemplate';

type TemplateListItemProps = {
	template: FormTemplate;
};

const TemplateListItem: FC<React.PropsWithChildren<TemplateListItemProps>> = ({ template }) => {
	const {
		closeRenameDialog,
		handleRenameTemplate,
		isSaving,
		renameDialogOpen,
		renameTemplate,
	} = useRenameTemplate(template);

	const {
		closeDeleteDialog,
		deleteDialogOpen,
		deleteTemplate,
		handleDeleteTemplate,
		isDeleting,
	} = useDeleteTemplate(template);

	const {
		closeDuplicateDialog,
		duplicateDialogOpen,
		duplicateTemplate,
		handleDuplicateTemplate,
		isDuplicating,
	} = useDuplicateTemplate(template);

	const { created, menuItems, updated } = useMenuItems({
		template,
		renameTemplate,
		duplicateTemplate,
		deleteTemplate,
	});

	return (
		<>
			<ListItem
				title={template.name || 'Unamed Template'}
				content={[
					{ key: 'Creator', value: template.createdBy?.name || 'Unknown' },
					{
						key: 'Created',
						value: created,
					},
					{
						key: 'Updated',
						value: updated,
					},
				]}
				url={`/templates/${template.id}`}
				menuItems={menuItems}
				loading={isSaving || isDeleting || isDuplicating}
			/>
			<InputDialog
				handleClose={closeRenameDialog}
				handleSave={handleRenameTemplate}
				initialValue={template.name}
				open={renameDialogOpen}
				label="New template name"
				title="Rename Template"
			/>
			<ConfirmDialog
				handleClose={closeDeleteDialog}
				handleConfirm={handleDeleteTemplate}
				open={deleteDialogOpen}
				title="Delete Template"
				label={`Are you sure you want to delete ${template.name} template?`}
			/>
			<ConfirmDialog
				handleClose={closeDuplicateDialog}
				handleConfirm={handleDuplicateTemplate}
				open={duplicateDialogOpen}
				title="Duplicate Template"
				label={`Are you sure you want to duplicate ${template.name} template?`}
			/>
		</>
	);
};

export default TemplateListItem;
