import { styled } from '@mui/system';
import { FC } from 'react';
import { Button } from '@mui/material';
import { MenuButtonProps } from './MenuButton.types';
import { Add, ExpandMore } from '@mui/icons-material';
import theme from '../../../styles/theme';

const MenuButton: FC<MenuButtonProps> = ({ onClick, open, children, small = false }) => {
	return small ? (
		<CustomButton
			onClick={onClick}
			fullWidth={false}
			startIcon={<Add color="primary" />}
			open={open}
			small={small ? 1 : 0}
			title="Add block"
		/>
	) : (
		<CustomButton
			onClick={onClick}
			fullWidth={false}
			startIcon={<Add color="primary" />}
			open={open}
			small={small ? 1 : 0}
			endIcon={
				<ExpandMore
					style={{
						transform: `rotate(${open ? 180 : 360}deg)`,
						transition: '0.3s',
						color: open ? theme.palette.primary.main : theme.palette.common.white,
					}}
				/>
			}>
			{children}
		</CustomButton>
	);
};

const CustomButton = styled(Button)<{ open: boolean; small: number }>`
	width: fit-content;

	border-radius: 5px;
	padding: 6px 8px;

	color: ${({ open }) => (open ? theme.palette.primary.main : theme.palette.grey[300])};
	background-color: ${({ open }) => (open ? theme.palette.grey[700] : 'transparent')};

	text-transform: none;
	font-size: 14px;
	line-height: 1rem;

	border: ${({ open }) => `1px solid ${open ? 'transparent' : theme.palette.grey[800]}`};

	&:hover {
		background-color: ${({ open }) =>
			open ? theme.palette.grey[700] : theme.palette.grey[800]};
		color: ${({ open }) =>
			open ? theme.palette.primary.main : theme.palette.common.white};
	}

	${({ small }) =>
		small &&
		`

		padding:  4px;
		min-width: unset;
		gap: 0;

		span {
			margin: 0;
		}
		
	`}
`;

export default MenuButton;
