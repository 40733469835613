import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getV2Form } from '../../firebase';
import { filterNodes } from './utils';
import { useSearchParams } from 'react-router-dom';
import { GalleryNode, GalleryPhoto, V2FormRes } from './types';

type V2FormGalleryContextType = {
	formRes: V2FormRes | null;
	loadingMessage: string;
	errorMessage: string;
	fetchV2Form: (formId: string) => void;

	filteredNodesObj: {
		[parentId: string]: GalleryNode[];
	};
	photos: GalleryPhoto[];

	galleryIndex: number;
	setGalleryIndex: React.Dispatch<React.SetStateAction<number>>;

	showPdf: boolean;
	setShowPdf: React.Dispatch<React.SetStateAction<boolean>>;
};

export const V2FormGalleryContext = createContext<V2FormGalleryContextType>(
	{} as V2FormGalleryContextType
);
export const V2FormGalleryProvider = ({ children }: { children: React.ReactNode }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [initPhotoName] = useState(searchParams.get('name') || '');
	const [formRes, setFormRes] = useState<V2FormRes | null>(null);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [galleryIndex, setGalleryIndex] = useState(0);
	const [showPdf, setShowPdf] = useState(false);

	const fetchV2Form = useCallback((formId: string) => {
		setLoadingMessage('Loading form data...');
		getV2Form({ formId: formId })
			.then(res => {
				setLoadingMessage('');
				setFormRes(res.data as V2FormRes);
			})
			.catch(err => {
				console.error(err);
				setErrorMessage('Could not load form...');
			});
	}, []);

	const { filteredNodesObj, photos } = useMemo(
		() => filterNodes(formRes?.items || {}),
		[formRes?.items]
	);

	// Get the initial gallery index from the search params.
	const initIdx = useMemo(() => {
		return photos.findIndex(photo => photo.name === initPhotoName);
	}, [initPhotoName, photos]);

	// Changes the 'galleryIndex' to the given photo in the search params.
	useEffect(() => {
		if (initIdx !== -1) setGalleryIndex(initIdx);
	}, [initIdx]);

	// Changes the 'name' search param to match the current photo.
	const cachedPhotoName = useRef(initPhotoName);
	useEffect(() => {
		if (photos.length) {
			const newPhotoName = photos[galleryIndex].name;

			if (cachedPhotoName.current !== newPhotoName) {
				cachedPhotoName.current = newPhotoName;
				setSearchParams({ name: newPhotoName }, { replace: true });
			}
		}
	}, [galleryIndex, photos, setSearchParams]);

	return (
		<V2FormGalleryContext.Provider
			value={{
				formRes,
				loadingMessage,
				errorMessage,
				fetchV2Form,

				filteredNodesObj,
				photos,

				galleryIndex,
				setGalleryIndex,

				showPdf,
				setShowPdf,
			}}>
			{children}
		</V2FormGalleryContext.Provider>
	);
};
