import { Switch, styled } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { SwitchFieldProps } from './SwitchField.types';
import { TemplateContext } from '../../../context/TemplateProvider';

const SwitchField: FC<SwitchFieldProps> = ({ itemNode }) => {
	const [nodeValue, setNodeValue] = useState<boolean>(!!itemNode.value);
	const { updateValue, isViewMode } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.checked;
		setNodeValue(newValue);

		await updateValue(itemNode.id, newValue);
	};

	useEffect(() => {
		setNodeValue(!!itemNode.value);
	}, [itemNode.value]);

	return (
		<StyledFormSwitch
			checked={nodeValue}
			onChange={handleToggle}
			inputProps={{ 'aria-label': 'switch' }}
			disabled={isTemplate}
			isViewMode={isViewMode}
		/>
	);
};

const StyledFormSwitch = styled(Switch)<{ isViewMode?: boolean }>`
	${({ isViewMode }) =>
		isViewMode
			? `
    width: 40px;
    height: 24px;
    padding: 0;

    .MuiButtonBase-root {
      padding: 4px;
      width: 24px;
      height: 24px;

      &.Mui-checked {
        transform: translateX(16px);
      }
    }

    .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
    }
    `
			: `
    width: 52px;
    height: 32px;
    padding: 0;

    .MuiButtonBase-root {
      padding: 6px;
    }
    `}

	.MuiButtonBase-root {
		&.Mui-checked {
			& + .MuiSwitch-track {
				background-color: ${({ theme }) => theme.palette.primary.main};
			}
		}

		& + .MuiSwitch-track {
			background-color: #545458;
			border: 2px solid ${({ theme }) => theme.palette.secondary.light};
			border-radius: 20px;
			opacity: 1;
		}
	}

	.MuiSwitch-thumb {
		background-color: ${({ theme }) => theme.palette.secondary.light};
	}
`;

export default SwitchField;
