import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GroupedStatusTableContext } from '../GroupedStatusTableProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import SkeletonTable from '../../SkeletonTable';
import { getStatusGroupColumns } from './columns';
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useTable,
	useRowSelect,
	useFlexLayout,
	useRowState,
} from 'react-table';
import { ProjectQueryResult, ProjectStatus } from '../../../../types';
import {
	getStatusGroupProjectCounts,
	queryStatusGroupProjects,
} from '../../../../firebase';
import { StatusGroupName, statusGroups } from '../utils';
import { formatProjectTimestamps, projectStatuses } from '../../../../utils';
import { LoadingContext } from '../../../../context/LoadingProvider';
import {
	BodyCell,
	BodyRow,
	BottomRow,
	HeaderCell,
	HeaderRow,
	Pagination,
	PaginationButton,
	Table,
} from '../../../styled-components/styledReactTable';
import {
	ChevronLeft,
	ChevronRight,
	ExpandLess,
	ExpandMore,
	FilterAlt,
} from '@mui/icons-material';
import Spinner from '../../Spinner';
import EmptyStateMessage from '../../EmptyStateMessage';
import { useNavigate } from 'react-router-dom';
import { UserHomeContext } from '../../../../context/UserHomeProvider';
import { FirestoreTimeStampObj } from '../../../../types/firestore';
import theme from '../../../../styles/theme';
import { Button, Menu, MenuItem } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/system';
import { SnackContext } from '../../../../context/SnackProvider';

const initPageSize = 14;

interface GroupedTableProps {
	projectsFoundNumber: (value: number) => void;
}

export const GroupedTable = ({ projectsFoundNumber }: GroupedTableProps) => {
	const {
		loadingProjects,
		setLoadingProjects,
		loadingProjectStatuses,
		setLoadingProjectStatuses,
	} = useContext(LoadingContext);
	const { projectsListQuery, selectedStatusGroup } = useContext(ProjectContext);
	const { setStatusGroupCounts, statusGroupProjects, setStatusGroupProjects } =
		useContext(GroupedStatusTableContext);
	const { setLoadingProject, selectedTeam, setProjectsFiltered } =
		useContext(UserHomeContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const navigate = useNavigate();

	const columns = useMemo(() => getStatusGroupColumns(), []);

	const [controlledPageSize, setControlledPageSize] = useState(initPageSize);
	const [controlledPageIndex, setControlledPageIndex] = useState(0);

	const [loadingNextPage, setLoadingNextPage] = useState(false);
	const [sortStatus, setSortStatus] = useState(false);
	const [sortDeliveryDate, setSortDeliveryDate] = useState<boolean | undefined>(
		undefined
	);
	const [anchorElFilterStatus, setAnchorElFilterStatus] = useState<null | HTMLElement>(
		null
	);
	const [anchorElFilterDeliveryDate, setAnchorElFilterDeliveryDate] =
		useState<null | HTMLElement>(null);
	const [filterStatusGroup, setFilterStatusGroup] = useState<ProjectStatus[]>();
	const [filterDeliveryDate, setFilterDeliveryDate] = useState<string | undefined>(
		undefined
	);

	const openFilterStatus = Boolean(anchorElFilterStatus);
	const openFilterDeliveryDate = Boolean(anchorElFilterDeliveryDate);

	const handleOpenFilterStatus = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElFilterStatus(event.currentTarget);
	};

	const handleCloseFilterStatus = () => {
		setAnchorElFilterStatus(null);
	};

	const handleOpenFilterDeliveryDate = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElFilterDeliveryDate(event.currentTarget);
	};

	const handleCloseFilterDeliveryDate = () => {
		setAnchorElFilterDeliveryDate(null);
	};

	const handleClearFilterDeliveryDate = () => {
		handleCloseFilterDeliveryDate();
		setFilterDeliveryDate(undefined);
	};

	useEffect(() => {
		projectsFoundNumber(statusGroupProjects.length);
	}, [statusGroupProjects, projectsFoundNumber]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageCount,
		setPageSize,
		gotoPage,
	} = useTable(
		{
			data: statusGroupProjects,
			columns: columns,
			initialState: {
				pageIndex: controlledPageIndex,
				pageSize: controlledPageSize,
			},
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		useFlexLayout,
		useRowState
	);

	const cachedLength = useRef(statusGroupProjects.length);
	useEffect(() => {
		cachedLength.current = statusGroupProjects.length;
	}, [statusGroupProjects]);

	const cachedSelectedGroupCount = useRef(statusGroupProjects.length);
	useEffect(() => {
		cachedSelectedGroupCount.current = statusGroupProjects.length;
	}, [statusGroupProjects.length, selectedStatusGroup]);

	const pageMin = controlledPageIndex * controlledPageSize + 1;
	const pageMax =
		controlledPageIndex + 1 === pageCount
			? statusGroupProjects.length
			: (controlledPageIndex + 1) * controlledPageSize;

	const canNextPage = pageMax < statusGroupProjects.length;
	const canPreviousPage = pageMin !== 1;
	const statusOrder = useCallback(() => {
		return [
			'Proposal Pending',
			'Proposal Sent',
			'To Be Scheduled',
			'On-Site Capture Complete',
			'Registration',
			'Ready For Modeling',
			'Modeling In Progress',
			'QA/QC',
			'Pending Payment',
			'Pending Payment / Delivered',
			'Delivered',
			'Sample',
			'Template',
		];
	}, []);

	useEffect(() => {
		setPageSize(controlledPageSize);
	}, [controlledPageSize, setPageSize]);

	useEffect(() => {
		gotoPage(controlledPageIndex);
	}, [controlledPageIndex, gotoPage]);

	// Cached the states so that only the appropriate useEffect triggers when a state is changed.
	const cachedQuery = useRef<string | null>(null);
	const cachedStatusGroup = useRef(selectedStatusGroup);
	const cachedPageSize = useRef(controlledPageSize);
	const cachedPageIndex = useRef(controlledPageIndex);

	// Cache the time at which queries are made so that the state is only updated by
	// the latest queries.
	const cachedQueryTime = useRef(0);
	const cachedStatusTime = useRef(0);

	const cursor = useRef<any>(null);

	const fetchSetProjects = useCallback(
		async (queryStr: string, limit: number, offset: number) => {
			if (limit > 0) {
				setLoadingProject(true);
				const time = new Date().getTime();
				cachedQueryTime.current = time;

				const res = await queryStatusGroupProjects({
					statusGroup: filterStatusGroup || projectStatuses,
					query: queryStr,
					limit: 6000,
					offset: offset,
					cursor: cursor.current,
					sharedTeam: selectedTeam?.id,
					deliveryDateFilter: filterDeliveryDate,
				});
				const fetchedProjects = (res.data as ProjectQueryResult).projects;

				if (fetchedProjects.length === 0 && filterDeliveryDate) {
					setLoadingProjects(false);
					setFilterDeliveryDate(undefined);

					setSnackbarProps({
						open: true,
						message: 'There are no projects for the selected date.',
						severity: 'warning',
					});
					return;
				}

				if (sortStatus) {
					fetchedProjects.sort((a, b) => {
						const statusAIndex = statusOrder().indexOf(a.projectStatus);
						const statusBIndex = statusOrder().indexOf(b.projectStatus);

						return statusBIndex - statusAIndex;
					});
				} else {
					fetchedProjects.sort((a, b) => {
						const statusAIndex = statusOrder().indexOf(a.projectStatus);
						const statusBIndex = statusOrder().indexOf(b.projectStatus);

						return statusAIndex - statusBIndex;
					});
				}

				if (sortDeliveryDate !== undefined) {
					fetchedProjects.sort((a, b) => {
						const dateA = a.deliveryTimestamp
							? (a.deliveryTimestamp as any as FirestoreTimeStampObj)._seconds
							: 0;
						const dateB = b.deliveryTimestamp
							? (b.deliveryTimestamp as any as FirestoreTimeStampObj)._seconds
							: 0;

						if (sortDeliveryDate) return dateA - dateB;
						return dateB - dateA;
					});
				}

				if (selectedTeam) {
					setProjectsFiltered(fetchedProjects);
				} else {
					setProjectsFiltered([]);
				}

				if (cachedQueryTime.current === time) {
					setStatusGroupProjects(prev => {
						if (offset === 0) return formatProjectTimestamps(fetchedProjects);
						else return [...(prev || []), ...formatProjectTimestamps(fetchedProjects)];
					});
					setLoadingProjects(false);
				}

				cursor.current = (res.data as any).cursor;
				setLoadingProject(false);
			}
		},
		[
			setLoadingProject,
			filterStatusGroup,
			selectedTeam,
			filterDeliveryDate,
			sortStatus,
			sortDeliveryDate,
			setSnackbarProps,
			statusOrder,
			setProjectsFiltered,
			setStatusGroupProjects,
			setLoadingProjects,
		]
	);

	const fetchSetStatusGroupCounts = useCallback(
		async (queryStr: string) => {
			setLoadingProjectStatuses(true);

			const time = new Date().getTime();
			cachedStatusTime.current = time;

			const res = await getStatusGroupProjectCounts({
				statusGroups: statusGroups,
				query: queryStr,
			});

			if (cachedStatusTime.current === time) {
				setStatusGroupCounts(
					res.data as {
						[groupName: string]: number;
					}
				);
				setLoadingProjectStatuses(false);
			}
		},
		[setLoadingProjectStatuses, setStatusGroupCounts]
	);

	useEffect(() => {
		setLoadingProjects(true);
		fetchSetProjects(projectsListQuery, cachedPageSize.current * 2, 0);
	}, [fetchSetProjects, projectsListQuery, setLoadingProjects, selectedTeam]);

	// Handle query changes. Will handle initial project fetch and status group count fetch.
	useEffect(() => {
		if (projectsListQuery !== cachedQuery.current) {
			// Update cached query.
			cachedQuery.current = projectsListQuery;

			// Remove previous cursor.
			cursor.current = null;

			// Get new status counts.
			fetchSetStatusGroupCounts(projectsListQuery);

			// Get new projects.
			setLoadingProjects(true);
			fetchSetProjects(projectsListQuery, cachedPageSize.current * 2, 0).then(() => {
				// Set cached page index to zero before changing page index so that the changed
				// index does not trigger more fetches.
				cachedPageIndex.current = 0;
				setControlledPageIndex(0);
			});
		}
	}, [
		fetchSetProjects,
		fetchSetStatusGroupCounts,
		projectsListQuery,
		setLoadingProjects,
	]);

	// Handle status group changes.
	useEffect(() => {
		if (selectedStatusGroup !== cachedStatusGroup.current) {
			// Update cached status group.
			cachedStatusGroup.current = selectedStatusGroup;

			// Remove previous cursor.
			cursor.current = null;

			// Get projects.
			setLoadingProjects(true);
			fetchSetProjects(cachedQuery.current || '', cachedPageSize.current * 2, 0).then(
				() => {
					// Set cached page index to zero before changing page index so that the changed
					// index does not trigger more fetches.
					cachedPageIndex.current = 0;
					setControlledPageIndex(0);
				}
			);
		}
	}, [fetchSetProjects, selectedStatusGroup, setLoadingProjects]);

	// Handle page index changes.
	useEffect(() => {
		// Only fetch more projects if the new project index is greater than the old one and
		// if not all the projects were fetched.
		if (
			controlledPageIndex > cachedPageIndex.current &&
			cachedLength.current < (cachedSelectedGroupCount.current || 0)
		) {
			setLoadingNextPage(true);
			fetchSetProjects(
				cachedQuery.current || '',
				cachedPageSize.current,
				cachedLength.current
			).then(() => {
				setLoadingNextPage(false);
			});
		}
		// Update cached page index.
		cachedPageIndex.current = controlledPageIndex;
	}, [controlledPageIndex, fetchSetProjects]);

	// Handle page size changes.
	useEffect(() => {
		// Calculate the new page index such that the top row of the current page will also be
		// present in the new page.
		const newPageIndex = Math.floor(
			(cachedPageIndex.current * cachedPageSize.current) / controlledPageSize
		);

		// Calculate how many projects should be loaded after the page size is updated.
		const limit = (newPageIndex + 1) * controlledPageSize + controlledPageSize;

		// Only fetch projects if the amount of projects already fetched is less than how many projects
		// should be fetched and if not all projects were fetched.
		if (
			cachedLength.current < limit &&
			cachedLength.current < (cachedSelectedGroupCount.current || 0)
		) {
			setLoadingProjects(true);
			fetchSetProjects(cachedQuery.current || '', limit, cachedLength.current).then(
				() => {
					// Update the cached page index to the new page index before changing the hook
					// so that no additional fetches are triggered.
					cachedPageIndex.current = newPageIndex;
					setControlledPageIndex(newPageIndex);
				}
			);
		} else {
			// Update the page index cache and hook even if no new projects were fetched.
			cachedPageIndex.current = newPageIndex;
			setControlledPageIndex(newPageIndex);
		}
		// Updated cached page size.
		cachedPageSize.current = controlledPageSize;
	}, [controlledPageSize, fetchSetProjects, setLoadingProjects]);

	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		if (bodyRef.current && headerRef.current) {
			const { scrollLeft } = bodyRef.current;
			headerRef.current.scrollLeft = scrollLeft;
		}
	};

	const handleFilterStatusGroup = (status: StatusGroupName | undefined) => {
		if (status) {
			const statusFiltered = statusGroups[status];
			setFilterStatusGroup(statusFiltered);
		} else {
			setFilterStatusGroup(undefined);
		}

		handleCloseFilterStatus();
	};

	const handleSelectedFilterDeliveryDate = (date: Date) => {
		const startOfDay = new Date(date);
		startOfDay.setHours(0, 0, 0, 0);
		const startOfDayInSeconds = Math.floor(startOfDay.getTime() / 1000);

		const endOfDay = new Date(date);
		endOfDay.setHours(23, 59, 59, 999);
		const endOfDayInSeconds = Math.floor(endOfDay.getTime() / 1000);

		setFilterDeliveryDate(`${startOfDayInSeconds}-${endOfDayInSeconds}`);
		handleCloseFilterDeliveryDate();
	};

	return (
		<Container>
			{statusGroupProjects.length > 0 || loadingProjects || loadingProjectStatuses ? (
				!loadingProjects && !loadingProjectStatuses ? (
					<TableWrapper {...getTableProps()}>
						{headerGroups.map((headerGroup, index) => (
							<HeaderRowGrid
								{...headerGroup.getHeaderGroupProps()}
								key={index}
								style={{ height: '40px', minWidth: '100%' }}
								ref={headerRef}>
								{headerGroup.headers.map((col, idx) => {
									return (
										<HeaderCell
											key={idx}
											style={{
												display: 'flex',
												color: 'white',
												width: col.width,
												placeContent:
													idx + 1 === headerGroup.headers.length ? 'flex-end' : '',
											}}>
											{col.render('Header')}
											{col.render('Header') === 'Status' && (
												<>
													<FilterButton onClick={handleOpenFilterStatus}>
														<FilterIcon />
													</FilterButton>
													{!sortStatus ? (
														<div onClick={() => setSortStatus(true)}>
															<ExpandMore />
														</div>
													) : (
														<div onClick={() => setSortStatus(false)}>
															<ExpandLess />
														</div>
													)}
												</>
											)}
											{col.render('Header') === 'Delivery Date' && (
												<>
													<FilterButton onClick={handleOpenFilterDeliveryDate}>
														<FilterIcon />
													</FilterButton>
													{!sortStatus ? (
														<div onClick={() => setSortDeliveryDate(true)}>
															<ExpandMore />
														</div>
													) : (
														<div onClick={() => setSortDeliveryDate(false)}>
															<ExpandLess />
														</div>
													)}
												</>
											)}
										</HeaderCell>
									);
								})}
							</HeaderRowGrid>
						))}

						<TableResize ref={bodyRef} onScroll={handleScroll}>
							<div {...getTableBodyProps()}>
								{page.map(row => {
									prepareRow(row);
									return (
										<BodyRowGrid
											{...row.getRowProps()}
											key={row.original.id}
											onClick={() => navigate(`/projects/${row.original.id}`)}>
											{row.cells.map((cell, idx) => {
												return (
													<BodyCell
														{...cell.getCellProps()}
														key={`${idx} ${cell.value}`}
														style={{
															flex: 'flex',
															width: cell.column.width,
															placeContent:
																idx + 1 === row.cells.length ? 'flex-end' : '',
														}}>
														{cell.render('Cell')}
													</BodyCell>
												);
											})}
										</BodyRowGrid>
									);
								})}
							</div>
						</TableResize>

						<BottomRow>
							<Pagination>
								<div>Rows per page:</div>

								<select
									value={controlledPageSize}
									onChange={e => setControlledPageSize(Number(e.target.value))}>
									{[initPageSize, 25, 50, 100].map(num => (
										<option key={num} value={num}>
											{num}
										</option>
									))}
								</select>

								<div>{`${pageMin}-${pageMax} of ${statusGroupProjects.length}`}</div>

								<PaginationButton
									disabled={!canPreviousPage}
									onClick={() => {
										if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
									}}>
									<ChevronLeft />
								</PaginationButton>

								{!loadingNextPage ? (
									<PaginationButton
										disabled={!canNextPage}
										onClick={() => {
											if (canNextPage) {
												setControlledPageIndex(prev => prev + 1);
												// nextPage();
											}
										}}>
										<ChevronRight />
									</PaginationButton>
								) : (
									<Spinner size={50} />
								)}
							</Pagination>
						</BottomRow>

						<Menu
							open={openFilterStatus}
							anchorEl={anchorElFilterStatus}
							onClose={handleCloseFilterStatus}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}>
							<MenuItem onClick={() => handleFilterStatusGroup(undefined)}>All</MenuItem>
							{Object.keys(statusGroups).map((status, idx) => (
								<MenuItem
									key={idx}
									onClick={() => handleFilterStatusGroup(status as StatusGroupName)}>
									{status}
								</MenuItem>
							))}
						</Menu>

						<Menu
							open={openFilterDeliveryDate}
							anchorEl={anchorElFilterDeliveryDate}
							onClose={handleCloseFilterDeliveryDate}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateCalendar
									onChange={(value, selectionState) =>
										handleSelectedFilterDeliveryDate(new Date(value))
									}
									sx={{
										'.MuiTypography-root': {
											color: 'white',
										},
										'.MuiPickersDay-root': {
											color: 'white',
											'&.Mui-selected': {
												backgroundColor: theme.palette.primary.main,
											},
										},
										'.MuiPickersCalendarHeader-label': {
											color: 'white',
										},
										'.MuiPickersArrowSwitcher-root button': {
											color: 'white',
										},
									}}
								/>
							</LocalizationProvider>
							<Box display="flex" justifyContent="flex-end" p={2}>
								<Button onClick={handleClearFilterDeliveryDate}>Clear</Button>
								<Button onClick={handleCloseFilterDeliveryDate}>Close</Button>
							</Box>
						</Menu>
					</TableWrapper>
				) : (
					<SkeletonTable rows={3} />
				)
			) : (
				<EmptyStateMessage text={'No displayable projects.'} />
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;
	min-height: 0;
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;
	max-height: 100%;

	border-top: 1px solid #4e4e4e;
`;

export const HeaderRowGrid = styled(HeaderRow)`
	display: grid;
	grid-template-columns: 1fr 1fr 3fr 1fr 2fr 1fr;
`;

export const BodyRowGrid = styled(BodyRow)<{ completed?: boolean }>`
	display: grid !important;
	grid-template-columns: 1fr 1fr 3fr 1fr 2fr 1fr;

	background-color: #0c0c0c;
	&:hover {
		background-color: #1e1e23;
	}
`;

export const TableResize = styled(Table)`
	min-height: calc(100% - 80px);
	max-height: 100px;
`;

const FilterButton = styled.button`
	border: none;
	background-color: transparent;
	color: ${theme.palette.primary.main};
	cursor: pointer;
	padding: 0 3px 0 5px;
	margin-top: -5px;
`;

const FilterIcon = styled(FilterAlt)`
	font-size: 18px;
`;
