import { Button, Menu, MenuItem } from '@mui/material';
import theme from '../../../styles/theme';
import { styled } from '@mui/system';

export const Container = styled('div')`
	background-color: ${({ theme }) => theme.palette.secondary.main};
	color: ${({ theme }) => theme.palette.newText.primary};
`;

export const HeaderTitle = styled('div')`
	font-size: 32px;
	line-height: 40px;
`;

export const HeaderTitleContainer = styled('div')`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const Title = styled('div')`
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
`;

export const Description = styled('div')`
	font-size: 14px;
	line-height: 20px;
`;

export const IconsContainer = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const ImagesContainer = styled('div')`
	margin-top: 28px;
	margin-bottom: 28px;
`;

export const CustomMenu = styled(Menu)`
	top: 8px;

	.MuiPaper-root {
		background-color: ${theme.palette.secondary.main};
	}
`;

export const CustomMenuItem = styled(MenuItem)`
	padding: 0;
	white-space: normal;
`;

export const ViewModeContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
	gap: 5px;
`;

export const ViewModeToggle = styled(Button)`
	min-width: 140px;
	text-transform: none;
`;
