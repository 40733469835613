import { Box, Typography } from '@mui/material';
import {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { debounce } from 'lodash';
import { InputFieldProps } from './InputField.types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { AuthContext } from '../../../context/AuthProvider';
import theme from '../../../styles/theme';
import { StyledTextField } from '../StyledTextField';
import { TemplateContext } from '../../../context/TemplateProvider';
import { Save } from '@mui/icons-material';

const debouncedSave = debounce(async (save: () => void) => save(), 400);

const InputField: FC<PropsWithChildren<InputFieldProps>> = ({
	node,
	variant = 'outlined',
	size = 'medium',
}) => {
	const { user } = useContext(AuthContext);
	const nodeValue = node.value as string;
	const { firebaseAuthData } = useContext(AuthContext);
	const { isTemplate } = useContext(TemplateContext);
	const [isFocused, setIsFocused] = useState(false);
	const [value, setValue] = useState<string>(nodeValue ?? '');
	const { updateValue, isViewMode } = useContext(FormV2Context);
	const [isSaving, setIsSaving] = useState(false);

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const newValue = event.target.value;
		setValue(newValue);
		setIsSaving(true);
		debounceSave(newValue);
	};

	const debounceSave = useCallback(
		(value: string) => {
			debouncedSave.cancel();
			debouncedSave(async () => {
				await updateValue(node.id, value);
				setIsSaving(false);
			});
		},
		[node.id, updateValue]
	);

	useEffect(() => {
		if (
			!isFocused &&
			node.value &&
			node.value !== value &&
			node.updatedBy?.id !== user?.id
		) {
			setValue(node.value as string);
		}
	}, [node.value, isFocused, value, node.updatedBy?.id, user?.id]);

	const editable = !node.focusedBy || node.focusedBy.id === firebaseAuthData?.uid;

	return (
		<Box display={'flex'} flexDirection={'column'} alignItems="start">
			<StyledTextField
				placeholder="Enter text"
				label={isViewMode ? '' : 'Value'}
				value={value}
				onChange={handleInputChange}
				onBlur={onBlur}
				onFocus={onFocus}
				disabled={!editable || isTemplate}
				variant={variant}
				size={size}
				id={node.id}
				InputProps={{
					endAdornment: isSaving ? <Save color="primary" /> : undefined,
				}}
				isViewMode={isViewMode}
			/>

			{node.focusedBy && node.focusedBy.id !== firebaseAuthData?.uid ? (
				<Typography sx={{ color: theme.palette.newText.primary }}>
					{node.focusedBy.name} is typing...
				</Typography>
			) : (
				<span />
			)}
		</Box>
	);
};

export default InputField;
