import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
	Announcements,
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragStartEvent,
	DragOverlay,
	DragMoveEvent,
	DragEndEvent,
	DragOverEvent,
	MeasuringStrategy,
	DropAnimation,
	Modifier,
	defaultDropAnimation,
	UniqueIdentifier,
} from '@dnd-kit/core';
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
	buildTree,
	flattenTree,
	getProjection,
	getChildCount,
	removeItem,
	removeChildrenOf,
} from './utilities';
import type { FlattenedItem, SensorContext, TreeItems } from './types';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import { SortableTreeItem } from './components';
import { CSS } from '@dnd-kit/utilities';
import { FormNode } from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import { MenuReorderContext } from '../../../screen-components/Form/Form';
import { SnackContext } from '../../../../context/SnackProvider';

const measuring = {
	droppable: {
		strategy: MeasuringStrategy.Always,
	},
};

const dropAnimationConfig: DropAnimation = {
	keyframes({ transform }) {
		return [
			{ opacity: 1, transform: CSS.Transform.toString(transform.initial) },
			{
				opacity: 0,
				transform: CSS.Transform.toString({
					...transform.final,
					x: transform.final.x + 5,
					y: transform.final.y + 5,
				}),
			},
		];
	},
	easing: 'ease-out',
	sideEffects({ active }) {
		active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
			duration: defaultDropAnimation.duration,
			easing: defaultDropAnimation.easing,
		});
	},
};

interface Props {
	collapsible?: boolean;
	defaultItems?: TreeItems;
	indentationWidth?: number;
	indicator?: boolean;
	removable?: boolean;
	onReorder: (active: FormNode, over: FormNode, items: FlattenedItem[]) => void;
}

export function SortableTree({
	collapsible,
	defaultItems = [],
	indicator = false,
	indentationWidth = 20,
	removable,
	onReorder,
}: Props) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { expandedList, toggleItem, setSelectedItem } = useContext(MenuReorderContext);

	const [items, setItems] = useState(() => defaultItems);
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
	const [offsetLeft, setOffsetLeft] = useState(0);
	const [currentPosition, setCurrentPosition] = useState<{
		parentId: UniqueIdentifier | null;
		overId: UniqueIdentifier;
	} | null>(null);

	const flattenedItems = useMemo(() => {
		const flattenedTree = flattenTree(items);
		const collapsedItems = flattenedTree.reduce<string[]>(
			(acc, { children, id }) =>
				!expandedList.includes(id.toString()) && children.length
					? [...acc, `${id}`]
					: acc,
			[]
		);

		return removeChildrenOf(
			flattenedTree,
			activeId ? [activeId, ...collapsedItems] : collapsedItems
		);
	}, [activeId, expandedList, items]);

	const projected =
		activeId && overId
			? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
			: null;

	const sensorContext: SensorContext = useRef({
		items: flattenedItems,
		offset: offsetLeft,
	});

	const [coordinateGetter] = useState(() =>
		sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter,
		})
	);

	const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
	const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

	useEffect(() => {
		sensorContext.current = {
			items: flattenedItems,
			offset: offsetLeft,
		};
	}, [flattenedItems, offsetLeft]);

	const announcements: Announcements = {
		onDragStart({ active }) {
			return `Picked up ${active.id}.`;
		},
		onDragMove({ active, over }) {
			return getMovementAnnouncement('onDragMove', active.id, over?.id);
		},
		onDragOver({ active, over }) {
			return getMovementAnnouncement('onDragOver', active.id, over?.id);
		},
		onDragEnd({ active, over }) {
			return getMovementAnnouncement('onDragEnd', active.id, over?.id);
		},
		onDragCancel({ active }) {
			return `Moving was cancelled. ${active.id} was dropped in its original position.`;
		},
	};

	useEffect(() => {
		setItems(defaultItems);
	}, [defaultItems]);

	return (
		<DndContext
			accessibility={{ announcements }}
			sensors={sensors}
			collisionDetection={closestCenter}
			measuring={measuring}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}>
			<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
				{flattenedItems.map(({ id, depth, node }) => (
					<SortableTreeItem
						key={id}
						id={id}
						value={`${node.displayOrder} ${node.displayTitle}`}
						// title={node.id}
						depth={id === activeId && projected ? projected.depth : depth}
						indentationWidth={indentationWidth}
						indicator={indicator}
						collapsed={Boolean(!expandedList.includes(id.toString()))}
						onCollapse={
							collapsible && node.type === 'node'
								? () => toggleItem(id.toString())
								: undefined
						}
						onRemove={removable ? () => handleRemove(id) : undefined}
						node={node}
						onClick={() =>
							setSelectedItem(prev => {
								if (prev?.id === node.id) return undefined;
								else return node;
							})
						}
					/>
				))}
				{createPortal(
					<DragOverlay
						dropAnimation={dropAnimationConfig}
						modifiers={indicator ? [adjustTranslate] : undefined}>
						{activeId && activeItem ? (
							<SortableTreeItem
								id={activeId}
								depth={activeItem.depth}
								clone
								childCount={getChildCount(items, activeId) + 1}
								value={`${activeItem.node.displayOrder} ${activeItem.node.displayTitle}`}
								indentationWidth={indentationWidth}
								node={activeItem.node}
							/>
						) : null}
					</DragOverlay>,
					document.body
				)}
			</SortableContext>
		</DndContext>
	);

	function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
		setActiveId(activeId);
		setOverId(activeId);

		const activeItem = flattenedItems.find(({ id }) => id === activeId);

		if (activeItem) {
			setCurrentPosition({
				parentId: activeItem.parentId,
				overId: activeId,
			});
		}

		document.body.style.setProperty('cursor', 'grabbing');
	}

	function handleDragMove({ delta }: DragMoveEvent) {
		setOffsetLeft(delta.x);
	}

	function handleDragOver({ over }: DragOverEvent) {
		setOverId(over?.id ?? null);
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		resetState();

		if (projected && over) {
			const { depth, parentId } = projected;
			const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
			const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
			const overTreeItem = clonedItems[overIndex];
			const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
			const activeTreeItem = clonedItems[activeIndex];
			const parentItem = clonedItems.find(({ id }) => id === parentId);

			if (depth === 0 && !['photos', 'node'].includes(activeTreeItem.node.type)) {
				setSnackbarProps({
					open: true,
					severity: 'warning',
					message: 'Only sections and photos can be placed at the root level',
				});
				return;
			}

			const insideSection = depth > 0 && parentItem && parentItem.node.type === 'node';
			const isLastBlock = active.id === over.id;

			const newParentId = insideSection
				? (parentId as string)
				: isLastBlock
				? ''
				: overTreeItem.node.parentId ?? '';

			clonedItems[activeIndex] = {
				...activeTreeItem,
				depth,
				parentId,
				node: {
					...activeTreeItem.node,
					parentId: newParentId,
					order: overTreeItem.node.order,
				},
			};

			const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
			const newItems = buildTree(sortedItems);

			setItems(newItems);

			const reorderedNewItems: FlattenedItem[] = JSON.parse(
				JSON.stringify(flattenTree(newItems))
			);

			const newActiveItem = reorderedNewItems.find(({ id }) => id === active.id);
			const newOverItem = reorderedNewItems.find(({ id }) => id === over.id);

			if (newActiveItem && newOverItem) {
				onReorder?.(
					{
						...newActiveItem.node,
						order: newActiveItem.index,
						level: newActiveItem.depth,
						parentId: newActiveItem.parentId?.toString() || newActiveItem.node.parentId,
					},
					{ ...newOverItem.node, order: newOverItem.index, level: newOverItem.depth },
					reorderedNewItems
				);
			}
		}
	}

	function handleDragCancel() {
		resetState();
	}

	function resetState() {
		setOverId(null);
		setActiveId(null);
		setOffsetLeft(0);
		setCurrentPosition(null);

		document.body.style.setProperty('cursor', '');
	}

	function handleRemove(id: UniqueIdentifier) {
		setItems(items => removeItem(items, id));
	}

	function getMovementAnnouncement(
		eventName: string,
		activeId: UniqueIdentifier,
		overId?: UniqueIdentifier
	) {
		if (overId && projected) {
			if (eventName !== 'onDragEnd') {
				if (
					currentPosition &&
					projected.parentId === currentPosition.parentId &&
					overId === currentPosition.overId
				) {
					return;
				} else {
					setCurrentPosition({
						parentId: projected.parentId,
						overId,
					});
				}
			}

			const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
			const overIndex = clonedItems.findIndex(({ id }) => id === overId);
			const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
			const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

			const previousItem = sortedItems[overIndex - 1];

			let announcement;
			const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
			const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

			if (!previousItem) {
				const nextItem = sortedItems[overIndex + 1];
				announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
			} else {
				if (projected.depth > previousItem.depth) {
					announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
				} else {
					let previousSibling: FlattenedItem | undefined = previousItem;
					while (previousSibling && projected.depth < previousSibling.depth) {
						const parentId: UniqueIdentifier | null = previousSibling.parentId;
						previousSibling = sortedItems.find(({ id }) => id === parentId);
					}

					if (previousSibling) {
						announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
					}
				}
			}

			return announcement;
		}

		return;
	}
}

const adjustTranslate: Modifier = ({ transform }) => {
	return {
		...transform,
		y: transform.y - 25,
	};
};
