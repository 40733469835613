import { UtilityFormV2 } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { UserTag } from './project-roles';

export type ProjectStatus =
	| 'Proposal Pending'
	| 'Proposal Sent'
	| 'To Be Scheduled'
	| 'On-Site Capture Complete'
	| 'Registration'
	| 'Ready For Modeling'
	| 'Modeling In Progress'
	| 'QA/QC'
	| 'Pending Payment / Delivered'
	| 'Delivered'
	| 'Pending Payment'
	| 'Sample'
	| 'Template'
	| 'Archive';

export type FirestoreProjectStatus = {
	displayTitle: string;
	id: string;
};

export interface Public {
	storage?: {
		alloted: number;
		shareLimit: number;
		used: number;
	};
	user?: {
		email: string;
		name: string;
		uid: string;
	};
}

export interface Contractor {
	Address: string;
	Cameras: string[];
	Email: string;
	Name: string;
	Notes: string;
	Phone: string;
	Website: string;
	files: string[];
	id: string;
	lat: number;
	lng: number;
}

export interface UserObj {
	email: string;
	name: string;
	id: string;
	teamAdminIds?: string[];
}

export interface PendingShare {
	id: string;
	email: string;
	projectIds?: string[];
	teamIds?: string[];
	createdAt: Date;
	lastUpdated: Date;
	organizationIds: string[];
	orgOwnerIds?: string[];
}

export interface ResolvedShare extends PendingShare {
	resolvedAt: Date;
}

export type UserPerms = 'userManagement' | 'leads' | 'organizations';

export const UserPermsMap: Record<UserPerms, string> = {
	userManagement: 'User Management',
	leads: 'Leads',
	organizations: 'Organizations',
};

export interface QuickbooksAuth {
	realmId: string;
	accessToken: {
		createdAt: number; // Epoch milliseconds
		expiresIn: number; // Seconds
		intuitTid: string;
		token: string;
	};
	refreshToken: {
		createdAt: number; // Epoch milliseconds
		expiresIn: number; // Seconds
		intuitTid: string;
		token: string;
	};
}

export interface UserWithData {
	email: string;
	fullName: string;
	id: string;

	isAdmin?: boolean;
	isContractor?: boolean;

	userPerms?: Partial<Record<UserPerms, boolean>>;
	userTags?: Partial<Record<UserTag, boolean>>;

	newProjectIds?: string[];
	phone?: string;
	address?: string;

	teamIds?: string[];
	orgOwnerIds?: string[];
	disabled?: boolean;
	teamAdminIds?: string[];
}

export interface FirebaseFile {
	filename: string;
	downloadUrl: string;
	filepath: string;
	uploadDate: Date;
	lastUpdated: Date;
	fileSize?: number;
}

export interface TimeValue {
	minutes?: number;
	hours?: number;
	days?: number;
	weeks?: number;
	months?: number;
	years?: number;
}

/**
 * Type for creating projects. To get all project fields, use ProjectData.
 */
export interface ProjectBase {
	projectStatus: ProjectStatus;
	clients: string[];
	address: string;
	subTotal: string; // Admins and clients only; no contractors
	name: string;

	cameras: string[];
	equipment?: string[];

	captureTimestamps?: Date[];
	modelingStartTimestamp?: Date;
	modelingDueTimestamp?: Date;
	deliveryTimestamp?: Date;

	scanningTechnicians?: UserObj[];
	designSoftware: string[];
	modelers: string[];
	registrationManagers: UserObj[];
	qaQcManagers: UserObj[];
	accountManager?: UserObj;
	bimTechnicians: UserObj[];
	modelingScope: string;

	description: string;
	internalNotes: string; // Admin only
	owner: string; // Admin only
	lastContacted: string; // TODO: migrate data and field usage to a Timestamp, Admin only
	signedProposal: boolean;

	matterport: string[];
	twoDLinks: string[];
	threeDLinks: string[];
	pointCloudLinks: string[];
	invoiceLinks: string[]; // Admins and clients only; no contractors

	proposal: FirebaseFile[]; // Admins and clients only; no contractors
	pointCloud: FirebaseFile[];
	photos: FirebaseFile[];
	pdf: FirebaseFile[];
	autodesk: FirebaseFile[];

	accountsPayable: string[]; // Admins only
	storeNumber?: string;
}

/**
 * Type for projects.
 */
export interface ProjectData extends ProjectBase {
	public: {
		user?: {
			email: string;
			name: string;
			// uid: string;
		};
	};

	creator: string;
	createdAt: Date;
	lastStatusChange: Date;

	sharedUsers: string[]; // Access control, contains user ids

	// ----Optional Fields----
	id?: string;

	lat?: number; // Latitude/longitude only generated after looking at projects on map
	lng?: number;

	scanningCost?: number; // Admins only.
	registrationCost?: number; // Admins only.
	modelingCost?: number; // Admins only.

	existingDocs?: FirebaseFile[];
	existingDocsInProgress?: FirebaseFile[];
	existingDocsArchive?: FirebaseFile[];

	// In-progress and archived files
	proposalInProgress?: FirebaseFile[];
	proposalArchive?: FirebaseFile[];
	pointCloudInProgress?: FirebaseFile[];
	pointCloudArchive?: FirebaseFile[];
	photosInProgress?: FirebaseFile[];
	photosArchive?: FirebaseFile[];
	pdfInProgress?: FirebaseFile[];
	pdfArchive?: FirebaseFile[];
	autodeskInProgress?: FirebaseFile[];
	autodeskArchive?: FirebaseFile[];

	// TimeValues
	timeOnSite?: TimeValue;
	totalDuration?: TimeValue;

	vrDeliveryTimeline?: string;
	photoReportTimeline?: string;

	cpu?: string[];

	sharedTeams?: string[];
	orgs?: { id: string; name: string }[];
	sharedOrgs?: string[];

	invoiceNums?: string[];
	procore?: ProjectDataProcore[];
	expenseClass?: string;
	startTime?: {
		time: string;
		timezone: string;
	};

	sentCaptureEmail?: boolean;
}

export type ProjectDataProcore = {
	procoreProjectId: string;
	procoreOrganizationId: string;
	organizationId: string;
};

export interface ProjectDataWithForms extends ProjectData {
	forms: UtilityFormV2[];
}

export interface FetchedFirestoreTimestamp {
	_seconds: number;
	_nanoseconds: number;
}

// TODO: Make this type override the ProjectData type
export interface RawProjectData {
	createdAt: FetchedFirestoreTimestamp;
	lastStatusChange: FetchedFirestoreTimestamp;
}

export interface RouteProps {
	children?: React.ReactNode;
	redirectPath?: string;
}

export interface ContainerProps {
	children?: React.ReactNode;
}

export interface Modeler {
	name: string;
	statuses: ProjectStatus[];
	projects: {
		[key in ProjectStatus]?: string[];
	};
}

export interface Organization {
	id: string;
	name: string;
	ownerIds?: string[];
	projectIds?: string[];
	teamIds?: string[];
	adminNotes: string; // admin only
	orgNotes: string; // public notes that users can see if they belong to that org
	logo: FirebaseFile[];
	hostingTerms?: TimeValue;
	paymentTerms?: number;
	accountManager?: AccountManager;
	procoreId?: string;
}

export interface Team {
	id: string;
	name: string;
	organizationId: string;
	projectIds?: string[];
	userIds?: string[];
	projects?: ProjectData[];
	templateIds?: string[];
	users?: UserObj[]; // Does not actually exist in Firestore, is populated by the getTeam Firebase Function
}

export interface ProjectSharingReturnData {
	undocumentedEmails: string[];
	nonExistantProjectIds: string[];
	restrictedProjectIds: string[];
	sharingErrors: { email: string; alreadyShared: string[] }[];
}

export interface ProjectQueryCursor {
	status: ProjectStatus;
	offset: number;
}

export interface ProjectQueryResult {
	projects: ProjectData[];
	cursor?: ProjectQueryCursor;
}

export interface ZipFile {
	name: string;
	url: string;
	order: number;
}

export type AccountManager = {
	id: string;
	fullName: string;
	email: string;
};

export interface ProjectFlag {
	id: string;
	projectId: string;
	flaggerName: string;
	flaggerEmail: string;
	flaggerId: string;
	timestamp: Date;
}

export type ProcoreUpload = {
	id: string;
	procoreOrganizationId: string;
	procoreProjectId: string;
	projectId: string;
	organizationId: string;
	url: string;
	status: string;
};

export interface ProjectTravel {
	id: string;
	projectId: string;
	flaggerName: string;
	flaggerEmail: string;
	flaggerId: string;
	timestamp: Date;
}
