import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

interface Project {
	id: string;
	address: string;
	name: string;
	projectStatus?: string;
}

interface ProjectTableProps {
	isAssign?: boolean;
	rows: Project[];
	selectProject: (id: string) => void;
	selectedProject: string[];
}
export const ProjectTable: React.FC<ProjectTableProps> = ({
	rows,
	selectProject,
	selectedProject,
}) => {
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		selectProject(id);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const visibleRows = useMemo(
		() => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[page, rowsPerPage, rows]
	);

	useEffect(() => {
		setPage(0);
	}, [rows]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper
				sx={{
					width: '100%',
					mb: 2,
					backgroundColor: 'transparent',
					borderRadius: '5px',
					overflow: 'hidden',
				}}>
				<TableContainer>
					<Table aria-labelledby="tableTitle">
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = !!selectedProject.find(id => id === row.id);
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableRow
										hover
										onClick={event => handleClick(event, row.id)}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
										sx={{
											cursor: 'pointer',
										}}>
										<TableCell
											padding="checkbox"
											sx={{
												borderBottom: '1px solid #24242466',
												backgroundColor: '#595959',
											}}>
											<Checkbox
												color="primary"
												checked={isItemSelected}
												inputProps={{
													'aria-labelledby': labelId,
												}}
												sx={{
													'&:not(.Mui-checked)': {
														svg: {
															fill: '#fff',
														},
													},
												}}
											/>
										</TableCell>
										<TableCell
											component="th"
											id={labelId}
											scope="row"
											padding="none"
											sx={{
												borderBottom: '1px solid #24242466',
												backgroundColor: '#595959',
											}}>
											<Typography
												color="newText.primary"
												variant="caption"
												sx={{ mr: '10px' }}>
												{row.address || row.name}
											</Typography>
											<Typography color="secondary.light" variant="caption">
												{row.projectStatus}
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{rows.length > 5 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							backgroundColor: '#595959',
							borderTop: '1px solid #24242466',
							'.MuiTablePagination-selectLabel': {
								color: 'newText.primary',
							},
							'.MuiSelect-select': {
								color: 'newText.primary',
							},
							'.MuiTablePagination-displayedRows': {
								color: 'newText.primary',
							},
							'.MuiSvgIcon-root': {
								fill: '#fbfbfb',
							},
						}}
						color="newText.primary"
					/>
				)}
			</Paper>
		</Box>
	);
};
