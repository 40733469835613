import {
	Button,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import { FormNode } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { StyledDialog, StyledDialogActions } from './EditNodeOptionsDialog.styles';

interface EditNodeOptionsDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onSaveItem: (options: string[]) => void;
	node: FormNode;
}

export const EditNodeOptionsDialog: React.FC<React.PropsWithChildren<EditNodeOptionsDialogProps>> = ({
	isOpen,
	node,
	onClose,
	onSaveItem,
}) => {
	const [nodeOptions, setNodeOptions] = useState<string[]>(node.options || []);
	const [newOption, setNewOption] = useState('');

	const handleSaveClick = () => {
		onSaveItem(nodeOptions);
		setNewOption('');
		onClose();
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission on 'enter'
			handleAddOption();
		}
	};

	const handleRemoveOption = (optionToRemove: string) => {
		setNodeOptions(nodeOptions.filter(option => option !== optionToRemove));
	};

	const handleAddOption = () => {
		if (newOption.trim() !== '') {
			setNodeOptions(prev => [...prev, newOption.trim()]);
			setNewOption('');
		}
	};

	const handleNewOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOption(event.target.value);
	};

	return (
		<StyledDialog open={isOpen} onClose={onClose}>
			<DialogTitle>Edit item options</DialogTitle>
			<DialogContent>
				<Stack direction="row" spacing={1} alignItems="center">
					<TextField
						fullWidth
						margin="dense"
						id="new-option"
						label="Enter option for the Picker"
						type="text"
						variant="standard"
						value={newOption}
						onChange={handleNewOptionChange}
						onKeyDown={handleKeyDown}
					/>
					<IconButton onClick={handleAddOption} aria-label="add option">
						<AddCircleOutline />
					</IconButton>
				</Stack>
				{nodeOptions.map((option, index) => (
					<Stack direction="row" spacing={1} alignItems="center" key={index}>
						<TextField
							fullWidth
							margin="dense"
							variant="standard"
							value={option}
							InputProps={{
								readOnly: true,
								disableUnderline: true,
							}}
						/>
						<IconButton
							onClick={() => handleRemoveOption(option)}
							aria-label="remove option">
							<RemoveCircleOutline />
						</IconButton>
					</Stack>
				))}
			</DialogContent>
			<StyledDialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSaveClick}>Save</Button>
			</StyledDialogActions>
		</StyledDialog>
	);
};
