import { IconButton, ListItemIcon, MenuItem } from '@mui/material';
import {
	ChatOutlined,
	Check,
	Close,
	ContentCopy,
	Edit,
	Info,
	SettingsOutlined,
	UnfoldLess,
	UnfoldMore,
	ViewDay,
	ViewHeadline,
} from '@mui/icons-material';
import {
	flattenTree,
	formNodesToTreeItems,
} from '../../../FormNavigationMenu/FormNavigationMenu';
import {
	FormNode,
	FormTemplate,
} from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import theme from '../../../../../styles/theme';
import useFormNodes from '../../../../screen-components/Form/components/FormWrapper/useFormNodes';
import useFormTemplateNodes from '../../../../screen-components/Form/components/FormWrapper/useFormTemplateNodes';
import { StyledMenu } from '../../../StyledMenu';
import { AuthContext } from '../../../../../context/AuthProvider';
import { SnackContext } from '../../../../../context/SnackProvider';
import { FormV2Context } from '../../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { TemplateContext } from '../../../../../context/TemplateProvider';
import { CommentsIndicator } from './ActionButton.styles';
import { MenuReorderContext } from '../../../../screen-components/Form/Form';
import { db, duplicateItemAndChildren } from '../../../../../firebase';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useContext, useEffect, useRef, useState } from 'react';
import { CommentsDialog, EditNodeOptionsDialog, InfoDialog } from '../../dialogs';
import { WrapperIconButton } from '../../FormNodeWrapper.styles';

type NodeActionButtonProps = {
	node: FormNode;
	collapsedPressed?: boolean;
	setCollapsedPressed?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ActionButton({
	node,
	collapsedPressed,
	setCollapsedPressed,
}: NodeActionButtonProps) {
	const [editOptionsVisible, setEditOptionsVisible] = useState(false);
	const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);
	const { formId, isViewMode } = useContext(FormV2Context);
	const { isTemplate, duplicateTemplateNode, tempId } = useContext(TemplateContext);
	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
	const actionMenuOpen = Boolean(actionAnchorEl);
	const { setSnackbarProps } = useContext(SnackContext);
	const [infoDialogOpen, setInfoDialogOpen] = useState(false);
	const { allFormNodes } = useFormNodes(node.formId ?? '', node.projectId ?? '');
	const { allFormTemplateNodes } = useFormTemplateNodes(node.formId ?? '');
	const treeItems = formNodesToTreeItems(
		isTemplate ? allFormTemplateNodes : allFormNodes
	);
	const flattenedTree = flattenTree(treeItems);
	const { toggleAllItems, expandedList } = useContext(MenuReorderContext);
	const [expanded, setExpanded] = useState<boolean>(false);
	const itemsList = extractIds(flattenedTree.find(n => n.node.id === node.id));
	const showExpandAll = itemsList.includes(node.id);
	const _isMounted = useRef(true);
	const { user, firebaseAuthData } = useContext(AuthContext);
	const [commentsCount, setCommentsCount] = useState(0);

	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		// Fetch the current number of comments when the component mounts or node.id changes
		const fetchCommentsCount = async () => {
			const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
			const docSnap = await getDoc(itemRef);

			if (docSnap.exists() && docSnap.data().comments) {
				setCommentsCount(docSnap.data().comments.length);
			}
		};

		fetchCommentsCount();
	}, [node.id]);

	const handleClickActionMenu = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		setActionAnchorEl(event.currentTarget as unknown as HTMLElement);
	};

	const handleCloseActionMenu = () => {
		if (_isMounted.current) setActionAnchorEl(null);
	};

	const handleEditOptions = () => {
		handleCloseActionMenu();
		setEditOptionsVisible(true);
	};

	const handleUpdateOptions = async (options: string[]) => {
		if (node.id) {
			await updateDoc(doc(db, 'utility_forms_v2_items', node.id), { options });
		}
		handleCloseActionMenu();
	};

	const updateCommentsCount = (newCount: number) => {
		setCommentsCount(newCount);
	};

	const handleCommentsClick = async (
		event:
			| React.MouseEvent<HTMLLIElement, MouseEvent>
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.preventDefault();
		handleCloseActionMenu();
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const docSnap = await getDoc(itemRef);

		if (!docSnap.exists()) {
			setSnackbarProps({
				open: true,
				message: 'Error, another user deleted this item',
				severity: 'error',
				hideDuration: 6000,
			});
		} else {
			setCommentsDialogOpen(true);
		}
	};

	const handleCloseComments = async () => {
		setCommentsDialogOpen(false);
	};

	const handleDuplicateNode = async () => {
		handleCloseActionMenu();
		try {
			setSnackbarProps({
				open: true,
				message: 'Duplicating item, please wait...',
				hideDuration: 600000,
				severity: 'info',
			});
			isTemplate
				? await duplicateTemplateNode(node.id)
				: await duplicateItemAndChildren({
						itemId: node.id,
						formId: formId,
				  });
			setSnackbarProps({
				open: true,
				message: 'Success! The item has been duplicated!',
				severity: 'success',
			});
		} catch (error) {
			console.error('error copying node: ', error);
			setSnackbarProps({
				open: true,
				message: 'Error duplicating item. Please try again',
				severity: 'error',
			});
		}
	};

	const handleInfoClick = async () => {
		handleCloseActionMenu();
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const docSnap = await getDoc(itemRef);

		if (!docSnap.exists()) {
			setSnackbarProps({
				open: true,
				message: 'Error, another user deleted this item',
				severity: 'error',
				hideDuration: 6000,
			});
		} else {
			setInfoDialogOpen(true);
		}
	};

	const handleInfoClose = async () => {
		setInfoDialogOpen(false);
	};

	const handleRequired = async () => {
		handleCloseActionMenu();

		if (node.id) {
			if (isTemplate) {
				const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
				const item = (await getDoc(itemRef)).data() as FormTemplate;

				const newNodes = item?.nodes.map(n => {
					if (n.id === node.id) {
						return {
							...n,
							required: !n.required,
						};
					}
					return n;
				});

				await updateDoc(itemRef, {
					nodes: newNodes,
					updatedAt: serverTimestamp(),
					updatedBy: {
						id: firebaseAuthData?.uid || '',
						name: user?.fullName || '',
					},
				});
			} else {
				await updateDoc(doc(db, 'utility_forms_v2_items', node.id), {
					required: !node.required,
				});
			}
		}
	};

	const handleGroupedDisplay = async () => {
		handleCloseActionMenu();

		if (node.id) {
			if (isTemplate) {
				const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
				const item = (await getDoc(itemRef)).data() as FormTemplate;

				const newNodes = item?.nodes.map(n => {
					if (n.id === node.id) {
						return {
							...n,
							groupedDisplay: !n.groupedDisplay,
						};
					}
					return n;
				});

				await updateDoc(itemRef, {
					nodes: newNodes,
					updatedAt: serverTimestamp(),
					updatedBy: {
						id: firebaseAuthData?.uid || '',
						name: user?.fullName || '',
					},
				});
			} else {
				await updateDoc(doc(db, 'utility_forms_v2_items', node.id), {
					groupedDisplay: !node.groupedDisplay,
				});
			}
		}
	};

	const handleExpandAll = () => {
		handleCloseActionMenu();
		const shouldRemove = itemsList.every((element: string) =>
			expandedList.includes(element)
		);
		if (shouldRemove || expanded) {
			const newElementsSet = new Set(itemsList);
			toggleAllItems(expandedList.filter(element => !newElementsSet.has(element)));
		} else {
			const allElements = [...expandedList, ...itemsList];
			toggleAllItems([...new Set(allElements)]);
		}
		setCollapsedPressed && setCollapsedPressed(false);
	};

	useEffect(() => {
		if (expandedList.includes(node.id) && !collapsedPressed) {
			setExpanded(true);
		} else {
			setExpanded(false);
		}
	}, [expandedList, node.id, itemsList, collapsedPressed]);

	useEffect(() => {
		const ifAllChildExpanded = itemsList.every((element: string) =>
			expandedList.includes(element)
		);
		ifAllChildExpanded && setExpanded(true);
	}, [expandedList, itemsList]);

	if (isViewMode) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
				{node.type === 'node' && (
					<IconButton onClick={handleExpandAll} size="small">
						{expanded ? (
							<UnfoldLess sx={{ color: theme.palette.grey[500] }} />
						) : (
							<UnfoldMore sx={{ color: theme.palette.grey[500] }} />
						)}
					</IconButton>
				)}

				<WrapperIconButton>
					<IconButton
						onClick={handleCommentsClick}
						id={`${node.id}-comments-button`}
						size="small">
						<ChatOutlined sx={{ color: theme.palette.grey[500] }} />
						{commentsCount > 0 && <CommentsIndicator>{commentsCount}</CommentsIndicator>}
					</IconButton>
				</WrapperIconButton>

				<CommentsDialog
					open={commentsDialogOpen}
					onClose={handleCloseComments}
					node={node}
					updateCommentsCount={updateCommentsCount}
				/>
			</div>
		);
	}

	return (
		<>
			<IconButton onClick={handleClickActionMenu}>
				<SettingsOutlined sx={{ color: theme.palette.grey[500] }} />
			</IconButton>

			{/* Actions Menu */}
			<StyledMenu
				anchorEl={actionAnchorEl}
				open={actionMenuOpen}
				onClose={handleCloseActionMenu}>
				{!isTemplate && (
					<MenuItem onClick={handleInfoClick} style={{ alignItems: 'center' }}>
						<ListItemIcon>
							<Info />
						</ListItemIcon>
						Description
					</MenuItem>
				)}
				<MenuItem onClick={handleCommentsClick} style={{ alignItems: 'center' }}>
					<ListItemIcon>
						<ChatOutlined />
						{commentsCount > 0 && <CommentsIndicator>{commentsCount}</CommentsIndicator>}
					</ListItemIcon>
					View Comments
				</MenuItem>

				{node.type === 'select' ? (
					<MenuItem onClick={handleEditOptions}>
						<ListItemIcon>
							<Edit color="primary" />
						</ListItemIcon>
						Edit options
					</MenuItem>
				) : null}

				<MenuItem onClick={() => handleDuplicateNode()}>
					<ListItemIcon>
						<ContentCopy />
					</ListItemIcon>
					Duplicate block
				</MenuItem>

				{!showExpandAll && (
					<MenuItem onClick={() => handleRequired()}>
						<ListItemIcon>{node.required ? <Close /> : <Check />}</ListItemIcon>
						{node.required ? 'Not Required' : 'Required'}
					</MenuItem>
				)}

				{showExpandAll && (
					<MenuItem onClick={() => handleExpandAll()}>
						{expanded ? (
							<>
								<ListItemIcon>
									<UnfoldLess />
								</ListItemIcon>
								Collapse all
							</>
						) : (
							<>
								<ListItemIcon>
									<UnfoldMore />
								</ListItemIcon>
								Expand all
							</>
						)}
					</MenuItem>
				)}
				{showExpandAll && (
					<MenuItem onClick={() => handleGroupedDisplay()}>
						<ListItemIcon>
							{node.groupedDisplay ? <ViewDay /> : <ViewHeadline />}
						</ListItemIcon>
						{node.groupedDisplay ? 'Display Individually' : 'Display as Group'}
					</MenuItem>
				)}
			</StyledMenu>

			<EditNodeOptionsDialog
				isOpen={editOptionsVisible}
				node={node}
				onClose={() => setEditOptionsVisible(false)}
				onSaveItem={handleUpdateOptions}
			/>

			<CommentsDialog
				open={commentsDialogOpen}
				onClose={handleCloseComments}
				node={node}
				updateCommentsCount={updateCommentsCount}
			/>

			{infoDialogOpen && (
				<InfoDialog open={infoDialogOpen} onClose={handleInfoClose} node={node} />
			)}
		</>
	);
}

export const extractIds = (obj: any): string[] => {
	let result: string[] = [];

	if (!!obj && obj.children && obj.node.type === 'node') {
		result.push(obj.id.toString());

		obj.children.forEach((child: any) => {
			result = result.concat(extractIds(child));
		});
	}

	return result;
};
