import { useContext, useState } from 'react';
import { SnackContext } from '../../../../../context/SnackProvider';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { FormNode } from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { StyledButton } from './InfoDialog.styles';
import { Dialog } from '../../../Dialog';
import { StyledTextField } from '../../../StyledTextField';
import theme from '../../../../../styles/theme';

interface InfoDialogProps {
	open: boolean;
	node: FormNode;
	onClose: () => void;
	templateSave?: (info?: string) => void;
}

const InfoDialog = ({ templateSave, open, node, onClose }: InfoDialogProps) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [info, setInfo] = useState(node.info);
	const handleInfoSubmit = async () => {
		if (templateSave) {
			templateSave(info);
			onClose();
			return;
		}

		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const itemSnap = await getDoc(itemRef); // get doc snapshot

		if (!itemSnap.exists()) {
			onClose(); // Close the dialog
			setSnackbarProps({
				open: true,
				message: 'Another user deleted this item.',
				severity: 'error',
				hideDuration: 6000,
			});
			return;
		}

		try {
			onClose();

			await updateDoc(itemRef, { info: info });

			setSnackbarProps({
				open: true,
				message: 'Description saved successfully!',
				severity: 'success',
				hideDuration: 6000,
			});
		} catch (error) {
			console.error('Error saving info: ', error);
			setSnackbarProps({
				open: true,
				message: 'Error saving info. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleInfoSubmit();
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Node description"
			bottomActions={<StyledButton onClick={handleInfoSubmit}>Save</StyledButton>}>
			<StyledTextField
				multiline
				value={info}
				onChange={e => setInfo(e.target.value)}
				onKeyDown={handleKeyDown}
				minRows={8}
				maxRows={12}
				autoFocus
				fullWidth
				inputProps={{ style: { color: theme.palette.newText.primary } }}
			/>
		</Dialog>
	);
};

export default InfoDialog;
