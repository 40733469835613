import { Typography } from '@mui/material';
import { CoverImageItemProps } from './CoverImage.types';
import { StyledCard, StyledCardContent, StyledCardMedia } from './CoverImageStyles';

const CoverImageItem: React.FC<CoverImageItemProps> = ({
	image,
	index,
	hasLogo,
	handleClick,
}) => {
	return (
		<StyledCard onClick={handleClick}>
			<StyledCardMedia image={image.downloadURL} title={image.name} />
			<StyledCardContent>
				{!hasLogo && (
					<Typography variant="label" color="newText.primary">
						Image {index + 1}
					</Typography>
				)}
				<Typography variant="body2" color="newText.primary" fontWeight={600}>
					{hasLogo ? 'Company logo' : image.name}
				</Typography>
			</StyledCardContent>
		</StyledCard>
	);
};

export default CoverImageItem;
