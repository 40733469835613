import { useEffect, useState } from 'react';
import Layout from '../components/reusable-components/Layout';
import styled from 'styled-components';
import Users from '../components/screen-components/UserManagement/Users';
import { Tab, Tabs } from '@mui/material';
import theme from '../styles/theme';
import Organizations from '../components/screen-components/UserManagement/Organizations';

export default function UserManagement() {
	useEffect(() => {
		document.title = 'Neural Net | Robotic Imaging';
	}, []);

	const [tabIndex, setTabIndex] = useState(0);
	const changeTab = (e: React.SyntheticEvent, value: any) => {
		if (typeof value === 'number') setTabIndex(value);
	};

	return (
		<Layout title="Neural Net">
			<StyledTabs value={tabIndex} onChange={changeTab}>
				<Tab label="Users" value={0} />
				<Tab label="Organizations" value={1} />
			</StyledTabs>

			<TabPanel>
				{tabIndex === 0 ? <Users /> : null}
				{tabIndex === 1 ? <Organizations /> : null}
			</TabPanel>
		</Layout>
	);
}

const StyledTabs = styled(Tabs)`
	width: fit-content;
	margin: 0 auto;
	height: 48px;

	.MuiTab-root {
		color: ${theme.palette.primary.main};
	}
`;

const TabPanel = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	max-height: calc(100% - 48px);
	overflow: auto;
`;
