import { useState, createContext, useEffect, useContext } from 'react';
import { ContainerProps, FirestoreProjectStatus } from '../types';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from './AuthProvider';
import { SnackContext } from './SnackProvider';
import packageJson from '../../package.json';

interface SettingsPropsType {
	maxNodeContainerNestingLevel: number;
	projectStatuses: FirestoreProjectStatus[];
}

type SettingsContextType = {
	maxNodeContainerNestingLevel: number;
	projectStatuses: FirestoreProjectStatus[];
};

export const SettingsContext = createContext<SettingsContextType>(
	{} as SettingsContextType
);

export const SettingsProvider = ({ children }: ContainerProps) => {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [settings, setSettings] = useState<SettingsPropsType>({
		maxNodeContainerNestingLevel: 4,
		projectStatuses: [],
	});

	const loadSettings = async () => {
		const settingsRef = doc(db, 'cached_data', 'appSettings');
		const settingsDoc = await getDoc(settingsRef);
		const settings = settingsDoc.data() as SettingsPropsType;
		setSettings(settings);
	};

	const compareVersions = (v1: string, v2: string) => {
		const v1parts = v1.split('.').map(Number);
		const v2parts = v2.split('.').map(Number);

		for (let i = 0; i < 3; i++) {
			if (v1parts[i] === v2parts[i]) continue;
			return v1parts[i] > v2parts[i] ? 1 : -1;
		}
		return 0;
	};

	useEffect(() => {
		if (!user) return;

		const unsubscribe = onSnapshot(
			doc(db, 'cached_data/version'),
			async documentSnapshot => {
				if (documentSnapshot.exists()) {
					const newVersion = documentSnapshot.data()?.version;
					const currentVersion = packageJson.version;
					compareVersions(currentVersion, newVersion);
					if (!currentVersion || !newVersion) return;
					if (newVersion > currentVersion)
						setSnackbarProps({
							open: true,
							message:
								'A new version of the application is available. Click here to refresh the page.',
							severity: 'info',
							hideDuration: 0,
							onClick: () => window.location.reload(),
						});
				}
			}
		);
		return unsubscribe;
	}, [setSnackbarProps, user]);

	useEffect(() => {
		if (user) loadSettings();
	}, [user]);

	return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
};
