import { isEmpty } from 'lodash';
import { ProjectData } from '../../../types';

export type ProjectCategory = 'projectScope' | 'scanning' | 'bim' | 'projectDetails';

export const projectCategoryTitles: { [category in ProjectCategory]: string } = {
	projectScope: 'Project Scope',
	scanning: 'Data Collection',
	bim: 'BIM',
	projectDetails: 'Project Details',
};

export const downloadCategories: ProjectCategory[] = ['projectScope', 'projectDetails'];
export const uploadCategories: ProjectCategory[] = ['bim'];

export const categoryFields: Record<ProjectCategory, (keyof ProjectData)[]> = {
	projectScope: [
		'accountManager',
		'address',
		'storeNumber',
		'expenseClass',
		'projectStatus',
		'sharedTeams',
		'proposal',
		'equipment',
		'captureTimestamps',
		'deliveryTimestamp',
		'scanningTechnicians',
		'registrationManagers',
		'cpu',
		'matterport',
		'invoiceNums',
		'invoiceLinks',
		'subTotal', // Includes margin.
		'description',
		// 'accountsPayable',
	],
	scanning: [],
	bim: [
		'modelers',
		'bimTechnicians',
		'qaQcManagers',
		'designSoftware',
		'modelingStartTimestamp',
		'modelingDueTimestamp',
		'modelingScope',
		'twoDLinks',
		'threeDLinks',
		'pointCloudLinks',
	],
	projectDetails: [
		'address',
		'storeNumber',
		'projectStatus',
		'sharedTeams',
		'proposal',
		'captureTimestamps',
		'deliveryTimestamp',
		'matterport',
	],
};

const checkFields = (project: ProjectData, fields: (keyof ProjectData)[]) => {
	for (const field of fields) {
		const val = project[field];
		if (!val) return false;

		if (Array.isArray(val) && val.length === 0) return false;
		else if (typeof val === 'string' && !val.trim()) return false;
		else if (typeof val === 'number') continue;
		else if (val instanceof Date) continue;
		else if (isEmpty(val)) return false;
	}

	return true;
};

export const validateCategory = (
	project: ProjectData,
	category: ProjectCategory,
	additionalConditions?: boolean[]
) => {
	let fieldCompletion = true;

	switch (category) {
		case 'projectScope': {
			fieldCompletion = checkFields(project, [
				'address',
				'clients',
				'accountManager',
				'vrDeliveryTimeline',
				'photoReportTimeline',
				'totalDuration',
				'scanningCost',
				'registrationCost',
				'modelingCost',
				'subTotal',
				'scanningTechnicians',
				'captureTimestamps',
				'equipment',
			]);
			break;
		}
		// case 'scanning': {
		// 	fieldCompletion = checkFields(project, [
		// 		'scanningTechnicians',
		// 		'captureTimestamps',
		// 		'equipment',
		// 	]);
		// 	break;
		// }
		case 'bim': {
			fieldCompletion = checkFields(project, [
				'modelers',
				'qaQcManagers',
				'designSoftware',
				'modelingStartTimestamp',
				'modelingDueTimestamp',
			]);
			break;
		}
	}

	return (
		fieldCompletion && !(additionalConditions || [true]).some(cond => cond === false)
	);
};
