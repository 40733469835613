import { useContext, useState } from 'react';
import { FormNode } from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { SnackContext } from '../../../../../context/SnackProvider';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { ConfirmDialog } from '../../../dialogs';
import { TemplateContext } from '../../../../../context/TemplateProvider';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import theme from '../../../../../styles/theme';

type DeleteButtonProps = {
	node: FormNode;
};

export default function DeleteButton({ node }: DeleteButtonProps) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);
	const { isTemplate, deleteTemplateNode } = useContext(TemplateContext);

	const handleDelete = async () => {
		setDialogOpen(false);
		if (node.id) {
			isTemplate
				? await deleteTemplateNode(node.id)
				: await deleteDoc(doc(db, 'utility_forms_v2_items', node.id));
		}
		setSnackbarProps({
			open: true,
			message: 'Success, item deleted!',
			severity: 'success',
			hideDuration: 6000,
		});
	};

	const handleClick = async () => {
		setDialogOpen(true);
	};

	return (
		<>
			<IconButton onClick={handleClick} id={`${node.id}-delete-button`}>
				<Delete sx={{ color: theme.palette.grey[500] }} />
			</IconButton>

			<ConfirmDialog
				handleClose={() => setDialogOpen(false)}
				handleConfirm={handleDelete}
				open={dialogOpen}
				title="Delete Item"
				label={`Are you sure you want to delete the item ${node.displayTitle}?`}
			/>
		</>
	);
}
