import {
	collection,
	getFirestore,
	where,
	limit,
	getDocs,
	documentId,
	query,
	orderBy,
} from 'firebase/firestore';
import { useState, useCallback, useEffect, useContext } from 'react';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { AuthContext } from '../../../context/AuthProvider';

const useTemplates = (open: boolean, projectId: string) => {
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [history, setHistory] = useState<FormTemplate[]>([]);
	const { user } = useContext(AuthContext);

	const fetchTemplates = useCallback(
		async (search: string) => {
			if (user?.isAdmin) {
				const baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where('name', '>=', search),
					where('name', '<', `${search}\uf8ff`),
					orderBy('name'),
					limit(5)
				);

				const templatesCollection = await getDocs(baseQuery);
				const templatesData = templatesCollection.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				return templatesData;
			} else {
				const queryByTeamIds = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where('teamIds', 'array-contains-any', user?.teamIds),
					where('name', '>=', search),
					where('name', '<', `${search}\uf8ff`),
					orderBy('name'),
					limit(5)
				);

				const queryByProjectId = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where('projectIds', 'array-contains', projectId),
					where('name', '>=', search),
					where('name', '<', `${search}\uf8ff`),
					orderBy('name'),
					limit(5)
				);

				const [resultByTeamIds, resultByProjectId] = await Promise.all([
					getDocs(queryByTeamIds),
					getDocs(queryByProjectId),
				]);

				const templatesDataByTeamIds = resultByTeamIds.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				const templatesDataByProjectId = resultByProjectId.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				const list: FormTemplate[] = [];

				for (const template of templatesDataByTeamIds) {
					if (!list.some(t => t.id === template.id)) list.push(template);
				}

				for (const template of templatesDataByProjectId) {
					if (!list.some(t => t.id === template.id)) list.push(template);
				}

				return list.slice(0, 5);
			}
		},
		[projectId, user?.isAdmin, user?.teamIds]
	);

	const onChangeQuery = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchQuery = event.target.value.toLocaleLowerCase();

		const templatesData = await fetchTemplates(searchQuery);

		setTemplates(templatesData);
	};

	const loadLatestTemplates = useCallback(async () => {
		const templatesData = await fetchTemplates('');
		setTemplates(templatesData);
	}, [fetchTemplates]);

	useEffect(() => {
		setTemplates(undefined);
		if (open) loadLatestTemplates();
	}, [loadLatestTemplates, open, setTemplates]);

	const addToHistory = async (template: FormTemplate) => {
		const json = localStorage.getItem('templateHistory');
		const history = json ? JSON.parse(json) : [];
		const newHistory = [...history, template.id];
		if (newHistory.length > 10) newHistory.shift();
		localStorage.setItem('templateHistory', JSON.stringify(newHistory));
		loadHistory(newHistory);
	};

	const loadHistory = useCallback(
		async (ids: string[]) => {
			if (ids.length === 0) return;
			let baseQuery;
			if (user?.isAdmin) {
				baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where(documentId(), 'in', ids),
					limit(3)
				);
			} else
				baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where(documentId(), 'in', ids),
					where('teamIds', 'array-contains-any', user?.teamIds),
					limit(3)
				);

			const historyQuery = await getDocs(baseQuery);

			setHistory(
				historyQuery.docs.map(item => ({ ...(item.data() as FormTemplate), id: item.id }))
			);
		},
		[user?.isAdmin, user?.teamIds]
	);

	useEffect(() => {
		const json = localStorage.getItem('templateHistory');
		if (!json) return;
		const history = JSON.parse(json);
		loadHistory(history);
	}, [loadHistory]);

	return {
		templates,
		history,
		onChangeQuery,
		addToHistory,
	};
};

export default useTemplates;
