import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { ClickableLink } from '../../../../../../styled-components/styledReactTable';
import ClickableSpan from '../../../../../ClickableSpan';
import { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import { StyledDialogContent } from '../../../../../../styled-components/dialog';
import Spinner from '../../../../../Spinner';
import styled from 'styled-components';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import { StatusTableContext } from '../../../../StatusTableProvider';

interface MultiLinkCellProps {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'matterport' | 'twoDLinks' | 'threeDLinks' | 'pointCloudLinks' | 'invoiceLinks';
	title: string;
}

export default function MultiLinkCell({
	row,
	rowEditing,
	field,
	title,
}: MultiLinkCellProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const projectData = row.original;
	const projectId = projectData.id || '';
	const links = projectData[field] || [];

	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [selectedLinks, setSelectedLinks] = useState(links);
	const [inputValue, setInputValue] = useState('');

	const handleOpen = () => {
		setSelectedLinks(links);
		setInputValue('');
		setOpen(true);
	};

	const handleClose = () => {
		if (!submitting) {
			setOpen(false);
		}
	};

	const removeLink = (idx: number) => {
		setSelectedLinks(prev => {
			const tmp = [...prev];
			tmp.splice(idx, 1);
			return tmp;
		});
	};

	const addLink = () => {
		const link = inputValue.trim();
		if (!link) {
			setSnackbarProps({
				open: true,
				message: 'No link to add...',
				severity: 'warning',
			});
			return;
		}

		const validPrefix = ['https://', 'http://'].some(prefix => link.startsWith(prefix));
		if (!validPrefix) {
			setSnackbarProps({
				open: true,
				message: 'Invalid link could not be added...',
				severity: 'warning',
			});
			return;
		}

		setSelectedLinks(prev => [...prev, link]);
		setInputValue('');
	};

	const handleSubmit = async () => {
		setSubmitting(true);

		try {
			await updateSingleProjectField(projectId, selectedLinks, field);
			setStatusProjects(prev => {
				if (prev) {
					return prev.map(proj => {
						if (proj.id === projectId) {
							return {
								...proj,
								[field]: selectedLinks,
							};
						} else return proj;
					});
				} else return prev;
			});
			handleClose();
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Updated ${title}!`,
			});
		} catch (err) {
			console.error(
				`Could not update links for ${projectData.address || 'Unknown project'}`
			);
			console.log(err);
			setSnackbarProps({
				open: true,
				message: `Error updating ${title}...`,
				severity: 'error',
			});
		} finally {
			setSubmitting(false);
		}
	};

	return !rowEditing ? (
		<LinkContainer>
			{links.length ? (
				links.map((link, idx) => (
					<ClickableLink
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						key={link + idx}
						onClick={e => e.stopPropagation()}
						title={link}>
						✓
					</ClickableLink>
				))
			) : (
				<span>-</span>
			)}
		</LinkContainer>
	) : (
		<>
			<ClickableSpan onClick={handleOpen} title="Click to edit link(s)">
				{`${links.length} link(s)`}
			</ClickableSpan>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{`Editing ${title}`}</DialogTitle>

				<StyledDialogContent $minWidth={450}>
					<Container>
						<ChipContainer>
							{selectedLinks.length ? (
								selectedLinks.map((item, idx) => {
									return (
										<Chip key={idx}>
											<ChipLink href={item} target="_blank" rel="noreferrer">
												{item}
											</ChipLink>

											<button onClick={() => removeLink(idx)}>X</button>
										</Chip>
									);
								})
							) : (
								<span>No links to display...</span>
							)}
						</ChipContainer>

						<InputRow>
							<TextField
								value={inputValue}
								onChange={e => setInputValue(e.target.value)}
								onKeyUp={e => {
									if (e.key === 'Enter') addLink();
								}}
								autoFocus
								fullWidth
							/>

							<Button sx={{ flexShrink: '0' }} onClick={addLink}>
								Add Link
							</Button>
						</InputRow>
					</Container>
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					{!submitting ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : (
						<Spinner size={50} />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}

const LinkContainer = styled.div`
	display: flex;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	min-width: 0px;
`;

const ChipContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	align-items: center;
	justify-content: center;

	padding: 20px;
	background-color: grey;
	border-radius: 5px;
`;

const Chip = styled.div`
	background-color: rgb(41, 41, 41);
	display: flex;
	align-items: center;
`;

const ChipLink = styled.a`
	margin: 0 5px;
	max-width: calc(300px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	color: #ffb310;

	&:hover,
	:focus {
		color: white;
	}
`;

const InputRow = styled.div`
	display: flex;
	gap: 10px;
`;
