import { useState } from 'react';
import { PhotoV2 } from '../../../../../../utils/types';
import { useSortable } from '@dnd-kit/sortable';
import { PhotoContainer, PhotoLoadingIcon } from '../../FormFillV2.styles';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';
import { getFlagStyle } from '../../../../../../utils/utils';

interface DraggablePhotoProps {
	photo: PhotoV2;
	index: number;
}

const SortablePhoto: React.FC<React.PropsWithChildren<DraggablePhotoProps & { openGallery: (index: number) => void }>> = ({ photo, index, openGallery }) => {
	const [isDragging, setIsDragging] = useState(false);

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: photo.name,
	});

	const handleMouseDown = () => {
		setIsDragging(false); // Assume dragging starts as false
	};

	const handleMouseUp = () => {
		if (!isDragging) {
			openGallery(index); //If not dragging, on click, open gallery dialog.
		}
	};

	const handleDragStart = () => {
		setIsDragging(true); // Set dragging to true when drag starts
	};

	const style = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
		transition,
	};

	const combinedListeners = {
		...listeners,
		onClick: () => openGallery(index),
	};

	return (
		<PhotoContainer
			ref={setNodeRef}
			style={{ ...style, ...getFlagStyle(photo.flag) }}
			{...attributes}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onDragStart={handleDragStart}
			{...combinedListeners}>
			{!photo.url ? (
				<PhotoLoadingIcon size={24} />
			) : (
				<LazyLoad>
					<Thumbnail
						src={`${process.env.REACT_APP_image_resizer_url}?imageUrl=${photo.url}&width=100&height=100`}
						alt={photo.originalName}
						loading="lazy"
					/>
				</LazyLoad>
			)}
		</PhotoContainer>
	);
};

export default SortablePhoto;

const Thumbnail = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
