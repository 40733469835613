import { useContext } from 'react';
import styled from 'styled-components';
import Carousel from '../../../components/reusable-components/Carousel';
import GalleryImg from './GalleryImg';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import GalleryControls from './GalleryControls';

export default function V2FormGallery() {
	const { formRes, photos, galleryIndex, setGalleryIndex, showPdf } =
		useContext(V2FormGalleryContext);

	const formData = formRes?.form;

	return (
		<Container>
			<GalleryControls />

			<CarouselContainer>
				<Carousel
					lazy
					lazyPreload={1}
					controlledIndex={galleryIndex}
					setControlledIndex={idx => setGalleryIndex(idx)}
					swipe={false}
					captureWindowKeypresses>
					{photos.map((photo, idx) => {
						return (
							<GalleryImg
								key={idx}
								photoIndex={idx}
								currentIndex={galleryIndex}
								photo={photo}
								nodeId={photo.node.id}
							/>
						);
					})}
				</Carousel>

				{showPdf ? <PDFOverlay src={formData?.downloadUrlVirtual} /> : null}
			</CarouselContainer>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const CarouselContainer = styled.div`
	flex-grow: 1;
	position: relative;
`;

const PDFOverlay = styled.iframe`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
`;
