import { FC, PropsWithChildren, useContext } from 'react';
import { FormNodeWrapperProps } from '../FormNodeWrapper/FormNodeWrapper.types';
import { MenuReorderContext } from '../../screen-components/Form/Form';
import { TemplateContext } from '../../../context/TemplateProvider';
import {
	ActionButtonsContainer,
	FlagWrapper,
	OrderAndTitle,
	WrapperContainer,
	WrapperIconButton,
	WrapperNode,
} from './ViewModeNodeWrapper.styles';
import { ActionButton, FlagButton } from '../FormNodeWrapper/buttons';
import DeleteButton from '../FormNodeWrapper/buttons/DeleteButton/DeleteButton';
import { Typography } from '@mui/material';

const ViewModeNodeWrapper: FC<PropsWithChildren<FormNodeWrapperProps>> = ({
	node,
	children,
}) => {
	const { selectedItem } = useContext(MenuReorderContext);
	const { isTemplate } = useContext(TemplateContext);
	const showHeader = !['node', 'placeholder'].includes(node.type);

	return (
		<WrapperContainer
			flag={node.flag}
			nodeType={node.type}
			level={node.level}
			id={node.id}
			isSelected={selectedItem?.id === node.id}>
			{showHeader && !isTemplate && (
				<FlagWrapper>
					<FlagButton node={node} />
				</FlagWrapper>
			)}

			{showHeader && (
				<OrderAndTitle>
					<Typography color="white" fontWeight="600">
						{node.displayOrder}
					</Typography>
					<Typography color="white" fontWeight="600">
						{node.displayTitle}
						{node.required && '*'}
					</Typography>
				</OrderAndTitle>
			)}

			<WrapperNode>{children}</WrapperNode>

			{showHeader && (
				<ActionButtonsContainer>
					<WrapperIconButton>
						<DeleteButton node={node} />
					</WrapperIconButton>
					<WrapperIconButton>
						<ActionButton node={node} />
					</WrapperIconButton>
				</ActionButtonsContainer>
			)}
		</WrapperContainer>
	);
};

export default ViewModeNodeWrapper;
