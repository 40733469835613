import React, { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { GalleryImage } from './PhotoGalleryDialog.styles';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Flag,
	PhotoV2,
} from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import Carousel from '../../../reusable-components/Carousel';
import { getFlagStyle } from '../../../screen-components/ProjectUtilityFormV2/utils/utils';
import theme from '../../../../styles/theme';
import { Dialog } from '../../Dialog';
import { StyledMenu } from '../../StyledMenu';
import { Flag as FlagIcon, Redo, RemoveCircle, Save, Undo } from '@mui/icons-material';
import { completedColor, reviewColor } from '../../FormNodeWrapper/buttons/FlagButton';
import { SnackContext } from '../../../../context/SnackProvider';
import Loading from '../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';

interface PhotoGalleryDialogProps {
	open: boolean;
	photos: PhotoV2[];
	initialIndex: number;
	onClose: () => void;
	onDelete: (index: number) => void;
	setFlag: (index: number, flag: Flag) => void;
	createOCR: (index: number) => void;
	rotateImage: (index: number, degrees: number) => Promise<void>;
}

const PhotoGalleryDialog: FC<PropsWithChildren<PhotoGalleryDialogProps>> = ({
	open,
	photos,
	initialIndex,
	onClose,
	onDelete,
	setFlag,
	createOCR,
	rotateImage,
}) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [currentIndex, setCurrentIndex] = useState(initialIndex);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [rotateDeg, setRotateDeg] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setCurrentIndex(initialIndex);
	}, [initialIndex]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		onDelete(currentIndex);

		if (photos.length <= 1) {
			onClose();
		} else if (currentIndex === photos.length - 1) {
			setCurrentIndex(currentIndex - 1);
		}
		handleClose();
	};

	const handleChangeIndex = (newIndex: number) => {
		setCurrentIndex(newIndex);
	};

	const handleSetFlag = (flag: Flag) => {
		setFlag(currentIndex, flag);
		handleClose();
	};

	const handleOCR = () => {
		createOCR(currentIndex);
		handleClose();
	};

	const handleRotate = (degrees: number) => {
		setRotateDeg((rotateDeg + degrees) % 360);
	};

	const handleSave = async () => {
		try {
			setIsLoading(true);
			await rotateImage(currentIndex, rotateDeg);
		} catch (error) {
			console.error('Error rotating image:', error);
			setSnackbarProps({
				open: true,
				message: `Error rotating image`,
				severity: 'error',
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		return () => {
			setRotateDeg(0);
		};
	}, []);

	const onImageLoad = () => {
		setRotateDeg(0);
		setIsLoading(false);
	};

	const saveIsDisabled = rotateDeg === 0 || isLoading;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={photos[currentIndex]?.originalName || ''}
			fullWidth
			fullHeight
			actions={
				<>
					<IconButton style={{ width: 40, height: 32 }}>
						{isLoading && <Loading />}
					</IconButton>
					<IconButton
						disabled={saveIsDisabled}
						onClick={handleSave}
						style={{
							color: saveIsDisabled ? undefined : theme.palette.primary.main,
						}}>
						<Save />
					</IconButton>
					<IconButton
						onClick={() => handleRotate(-90)}
						style={{ color: theme.palette.primary.main }}>
						<Undo />
					</IconButton>
					<IconButton
						onClick={() => handleRotate(90)}
						style={{ color: theme.palette.primary.main }}>
						<Redo />
					</IconButton>
					<IconButton
						aria-label="settings"
						aria-controls={openMenu ? 'settings-menu' : undefined}
						aria-expanded={openMenu ? 'true' : undefined}
						onClick={handleClick}
						style={{ color: theme.palette.primary.main }}>
						<SettingsIcon />
					</IconButton>
					<StyledMenu
						id="settings-menu"
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'settings-button',
						}}>
						<MenuItem onClick={handleDelete}>
							<ListItemIcon>
								<DeleteIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Delete Photo</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleOCR}>
							<ListItemIcon>
								<DeleteIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Create OCR input</ListItemText>
						</MenuItem>
						{photos[currentIndex]?.flag && (
							<MenuItem onClick={() => handleSetFlag('')}>
								<ListItemIcon>
									<RemoveCircle style={{ color: 'grey' }} />
								</ListItemIcon>
								Remove flag
							</MenuItem>
						)}
						{photos[currentIndex]?.flag !== 'review' && (
							<MenuItem onClick={() => handleSetFlag('review')}>
								<ListItemIcon>
									<FlagIcon fontSize="small" sx={{ color: reviewColor }} />
								</ListItemIcon>
								Mark as needs review
							</MenuItem>
						)}
						{photos[currentIndex]?.flag !== 'completed' && (
							<MenuItem onClick={() => handleSetFlag('completed')}>
								<ListItemIcon>
									<FlagIcon fontSize="small" sx={{ color: completedColor }} />
								</ListItemIcon>{' '}
								Mark as completed
							</MenuItem>
						)}
					</StyledMenu>
				</>
			}>
			<Carousel
				captureWindowKeypresses
				controlledIndex={currentIndex}
				setControlledIndex={handleChangeIndex}>
				{photos.map((photo, index) => (
					<GalleryImage
						onLoad={onImageLoad}
						key={index}
						degree={rotateDeg}
						src={photo.url || photo.thumbnail}
						alt="Gallery View"
						style={getFlagStyle(photo.flag)}
					/>
				))}
			</Carousel>
		</Dialog>
	);
};

export default PhotoGalleryDialog;
