import { useState, useEffect, useCallback } from 'react';
import { ProjectData } from '../../../../../../../types';
import { Row } from 'react-table';
import { excludedStatuses } from '../../utils';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import PlacesAutocompleteWrapper from '../../../../../PlacesAutocomplete/PlacesAutocompleteWrapper';
import { debounce } from 'lodash';
import { Typography } from '@mui/material';

export default function AddressCell({
	row,
	rowEditing,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
}) {
	const projectId = row.original.id as string;
	const initialAddress = row.original.address || row.original.name || 'Unnamed Project';
	const [addressState, setAddressState] = useState<{ address: string }>({
		address: initialAddress,
	});
	// const [address, setAddress] = useState(initialAddress);

	const [addressColor, setAddressColor] = useState<'white' | '#ffb310' | 'red'>('white');
	const projectData = row.original;

	useEffect(() => {
		if (projectData) {
			const deliveryTs = projectData.deliveryTimestamp;

			// Change 'addressColor' if a deliveryTimestamp is included and the 'projectStatus'
			// is not one of the excluded statuses.
			if (deliveryTs && !excludedStatuses.includes(projectData.projectStatus)) {
				const twoDayMillis = 1000 * 60 * 60 * 24 * 2;
				const fiveDayMillis = 1000 * 60 * 60 * 24 * 5;
				const today = new Date();
				const millisDiff = deliveryTs.getTime() - today.getTime();

				// Change address color based on difference between 'deliveryTimestamp' and today.
				if (millisDiff <= twoDayMillis) {
					// Within 2 days.
					setAddressColor('red');
				} else if (millisDiff <= fiveDayMillis) {
					// Within 5 days.
					setAddressColor('#ffb310');
				}
			}
		}
	}, [projectData, projectData.deliveryTimestamp, projectData.projectStatus]);

	useEffect(() => {
		if (addressState.address !== initialAddress) {
			debouncedUpdate(projectId, addressState.address);
		}
	}, [addressState.address, initialAddress, projectId]);

	const handleAddressChange = useCallback((newState: { address: string }) => {
		setAddressState(newState);
	}, []);

	return !rowEditing ? (
		<Typography variant="body2" color={addressColor}>
			{addressState.address}
		</Typography>
	) : (
		<PlacesAutocompleteWrapper
			formState={addressState}
			setFormState={handleAddressChange}
			addressField="address"
			fullWidth
			isStyled={true}
			style={{ width: '100%', color: '#ffb310' }}
		/>
	);
}

const debouncedUpdate = debounce((projectId: string, newAddress: string) => {
	updateSingleProjectField(projectId, newAddress, 'address')
		.then(() => console.log('Address updated in Firestore'))
		.catch(error => console.error('Error updating address in Firestore:', error));
}, 1000);
