import styled from 'styled-components';

export const GalleryImage = styled.img<{ degree?: number }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(${({ degree }) => degree}deg);
	max-width: 99%;
	max-height: 99%;
	object-fit: contain;
`;
