import { CoverImageMainProps, CoverImageProps } from './CoverImage.types';
import CoverImageItem from './CoverImageItem';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { useContext, useState } from 'react';
import CoverImageUpload from './CoverImageUpload';
import CoverImageDialog from './CoverImageDialog';
import { Box, Typography } from '@mui/material';
import {
	CoverContainer,
	CoverImageContainer,
	CoverLogoContainer,
} from './CoverImageStyles';

const CoverImage: React.FC<CoverImageMainProps> = ({ logoOnly, hasLogo }) => {
	const emptyState = {
		downloadURL: '',
		name: '',
		filepath: '',
	};
	const imageMaxCount = 3;
	const { utilityForm } = useContext(FormV2Context);
	const images = utilityForm?.coverImage || [];
	const logo = utilityForm?.clientLogo
		? [
				{
					downloadURL: utilityForm?.clientLogo || '',
					name: 'logo',
					filepath: '',
				},
		  ]
		: [];
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [editData, setEditData] = useState<CoverImageProps>(emptyState);
	const [editLogo, setEditLogo] = useState<boolean>(false);

	const uploadHandler = () => {
		setEditData(emptyState);
		setOpenDialog(true);
	};

	return (
		<>
			<CoverContainer haslogo={hasLogo ? 1 : 0} logoonly={logoOnly ? 1 : 0}>
				{(hasLogo || logoOnly) && (
					<Box position={'relative'}>
						<Typography
							variant="label"
							color="newText.primary"
							sx={{ display: 'inline-block', mb: '8px' }}>
							Company logo
						</Typography>
						<CoverLogoContainer hasborder={hasLogo ? 1 : 0}>
							{logo.map((item, index) => (
								<CoverImageItem
									key={index}
									index={index}
									image={item}
									hasLogo={hasLogo || !logoOnly}
									handleClick={() => {
										setEditData(item);
										setOpenDialog(true);
										setEditLogo(true);
									}}
								/>
							))}
							{!logo.length && (
								<CoverImageUpload onClick={() => [uploadHandler(), setEditLogo(true)]} />
							)}
						</CoverLogoContainer>
					</Box>
				)}
				{!logoOnly && (
					<Box>
						<Typography
							variant="label"
							color="newText.primary"
							sx={{ display: 'inline-block', mb: '8px' }}>
							Cover images (up to 3)
						</Typography>
						<CoverImageContainer>
							{images.map((item, index) => (
								<CoverImageItem
									key={index}
									index={index}
									image={item}
									handleClick={() => {
										setEditData(item);
										setOpenDialog(true);
										setEditLogo(false);
									}}
								/>
							))}
							{images.length < imageMaxCount && (
								<CoverImageUpload onClick={() => [uploadHandler(), setEditLogo(false)]} />
							)}
						</CoverImageContainer>
					</Box>
				)}
			</CoverContainer>
			{editData && !!Object.keys(editData).length && (
				<CoverImageDialog
					openDialog={openDialog}
					handleClose={() => setOpenDialog(false)}
					image={editData}
					hasLogo={editLogo}
				/>
			)}
		</>
	);
};

export default CoverImage;
