import { useContext, useState } from 'react';
import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { ClickableLink } from '../../../../../../styled-components/styledReactTable';
import ClickableSpan from '../../../../../ClickableSpan';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	TextField,
} from '@mui/material';
import { StyledDialogContent } from '../../../../../../styled-components/dialog';
import { SnackContext } from '../../../../../../../context/SnackProvider';
// import { isValidLink } from '../../../../../../../utils';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import Spinner from '../../../../../Spinner';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { QuickbooksContext } from '../../../../../../../context/QuickbooksProvider';

export default function InvoicesCell({
	row,
	rowEditing,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);
	const { downloadQBInvoice } = useContext(QuickbooksContext);

	const projectId = row.original.id as string;
	const invoiceLinks = row.original.invoiceLinks ?? [];
	const invoiceNums = row.original.invoiceNums || [];

	const [value, setValue] = useState(invoiceNums);
	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState('');
	const [editing, setEditing] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const handleOpen = () => {
		setValue(invoiceNums);
		setInputValue('');
		setError('');
		setEditing(true);
	};

	const handleClose = (event: React.MouseEvent) => {
		event.stopPropagation();
		setEditing(false);
	};

	/**
	 * Checks the given string array for invalid links. If an invalid
	 * link is found, sets the error useState and returns that link. If no invalid link
	 * is found, returns null.
	 */
	// const validateLinks = (links: string[]) => {
	// 	setError('');
	// 	const invalidLink = links.find(link => !isValidLink(link));
	// 	if (invalidLink) {
	// 		setError(`${invalidLink} is not a valid URL`);
	// 		return invalidLink;
	// 	} else return null;
	// };

	// const handleChange = (newValues: string[]) => {
	// 	const invalidLink = validateLinks([...newValues]);
	// 	if (invalidLink) setInputValue(invalidLink);
	// 	setValue(newValues.filter(link => isValidLink(link)));
	// };

	// const handleSubmit = async (event: React.MouseEvent) => {
	// 	event.stopPropagation();
	// 	let canSubmit = true;

	// 	// If there is an inputValue, validates it together with the value useState.
	// 	// If there is an invalid link, then prevents submit, else it adds the inputValue
	// 	// to the value useState and submits normally.
	// 	if (inputValue && !value.includes(inputValue)) {
	// 		const values = [...value, inputValue];
	// 		setInputValue('');
	// 		const invalidLink = validateLinks(values);
	// 		if (invalidLink) {
	// 			canSubmit = false;
	// 			setInputValue(invalidLink);
	// 		}
	// 		setValue(values.filter(link => isValidLink(link)));
	// 	}

	// 	if (!error && canSubmit) {
	// 		setDisabled(true);
	// 		setSnackbarProps({
	// 			open: true,
	// 			severity: 'warning',
	// 			message: 'Saving changes...',
	// 			hideDuration: null,
	// 		});

	// 		await updateSingleProjectField(projectId, value, 'invoiceLinks');
	// 		setStatusProjects(prev => {
	// 			if (prev) {
	// 				return prev.map(proj => {
	// 					if (proj.id === projectId) {
	// 						return {
	// 							...proj,
	// 							invoiceLinks: value,
	// 						};
	// 					} else return proj;
	// 				});
	// 			} else return prev;
	// 		});

	// 		setInputValue('');
	// 		setDisabled(false);
	// 		setSnackbarProps({
	// 			open: true,
	// 			severity: 'success',
	// 			message: 'Changes saved!',
	// 		});
	// 	} else {
	// 		setSnackbarProps({
	// 			open: true,
	// 			severity: 'error',
	// 			message: 'Cannot submit with errors!',
	// 		});
	// 	}
	// };

	const handleSubmitNums = async (event: React.MouseEvent) => {
		if (inputValue && !value.includes(inputValue)) {
			setValue(prev => [...prev, inputValue]);
			setInputValue('');
		}

		setDisabled(true);
		await updateSingleProjectField(projectId, value, 'invoiceNums');
		setStatusProjects(prev => {
			if (prev) {
				return prev.map(proj => {
					if (proj.id === projectId) {
						return {
							...proj,
							invoiceNums: value,
						};
					} else return proj;
				});
			} else return prev;
		});
		setInputValue('');
		setDisabled(false);
		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>
			{!invoiceNums.length && !invoiceLinks.length ? '-' : ''}
			{invoiceNums.length
				? invoiceNums.map((num, i) => {
						return (
							<ClickableLink
								key={num + i}
								onClick={e => {
									e.stopPropagation();
									downloadQBInvoice(num);
								}}>
								{'↓'}
							</ClickableLink>
						);
				  })
				: ''}
			{invoiceLinks.length
				? row.original.invoiceLinks.map((link, i) => (
						<ClickableLink
							href={link}
							target="_blank"
							rel="noopener noreferrer"
							key={link + i}
							onClick={event => event.stopPropagation()}>
							{'✓'}
						</ClickableLink>
				  ))
				: ''}
		</span>
	) : (
		<>
			<ClickableSpan onClick={handleOpen} title="Click to edit invoices">
				{`${invoiceNums.length} invoice(s)`}
			</ClickableSpan>

			<Dialog
				open={editing}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<DialogTitle>Editing Invoices</DialogTitle>

				<StyledDialogContent $minWidth={450}>
					<Autocomplete
						value={value}
						onChange={(e, newValues) => setValue(newValues)}
						options={[]}
						disabled={disabled}
						multiple
						freeSolo
						fullWidth
						renderInput={params => (
							<TextField
								{...params}
								autoFocus
								onChange={() => setError('')}
								error={!!error}
								helperText={error}
							/>
						)}
						inputValue={inputValue}
						onInputChange={(e, value) => [e.stopPropagation(), setInputValue(value)]}
					/>
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					{!disabled ? (
						<Button onClick={handleSubmitNums}>Submit</Button>
					) : (
						<Spinner size={50} />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}
