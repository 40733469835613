import { useMediaQuery } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import { CoverImage } from '../CoverImage';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import {
	Business,
	Edit,
	Group,
	Lock,
	UnfoldLess,
	UnfoldMore,
	Visibility,
} from '@mui/icons-material';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { BackButton } from '../../reusable-components/BackButton';
import Tools from './buttons/Tools';
import Download from './buttons/Download';
import Submit from './buttons/Submit';
import SwitchForm from './buttons/SwitchForm';
import { YellowButton } from '../../styled-components/styledReactTable';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { StyledIconButton } from './buttons/components';
import MenuItemContent from '../MenuItem/MenuItem';
import { FormDialog } from '../../../screens/NodesReorder/components/FormDialog';
import { AssignDialog } from '../../../screens/NodesReorder/components/FormDialog/AssignDialog';
import { TemplateContext } from '../../../context/TemplateProvider';
import { PDF } from './buttons/PDF';
import {
	Container,
	CustomMenu,
	CustomMenuItem,
	Description,
	HeaderTitle,
	HeaderTitleContainer,
	IconsContainer,
	ImagesContainer,
	Title,
	ViewModeContainer,
	ViewModeToggle,
} from './FormHeader.styles';
import { MenuReorderContext } from '../../screen-components/Form/Form';
import useFormNodes from '../../screen-components/Form/components/FormWrapper/useFormNodes';
import useFormTemplateNodes from '../../screen-components/Form/components/FormWrapper/useFormTemplateNodes';

const FormHeader: FC = () => {
	const matches = useMediaQuery('(max-width:1024px)');
	const { utilityForm, isViewMode, setIsViewMode } = useContext(FormV2Context);
	const { isTemplate, templateName } = useContext(TemplateContext);
	const { projectId }: any = useParams();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isAssignDialogOpen, setIsAssignDialogOpen] = useState<boolean>(false);
	const [assignType, setAssignType] = useState<'project' | 'team'>('project');
	const { toggleAllItems, expandedList } = useContext(MenuReorderContext);
	const [isAllExpanded, setIsAllExpanded] = useState(false);

	const { allFormNodes } = useFormNodes(
		utilityForm?.id ?? '',
		utilityForm?.projectId ?? ''
	);
	const { allFormTemplateNodes } = useFormTemplateNodes(utilityForm?.id ?? '');

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};

	const handleAssignDialog = () => {
		setIsAssignDialogOpen(!isAssignDialogOpen);
	};

	const toggleViewMode = () => {
		setIsViewMode(!isViewMode);
	};

	const getRootSections = () => {
		const nodes = isTemplate ? allFormTemplateNodes : allFormNodes;
		return nodes
			.filter(node => !node.parentId && node.type === 'node')
			.map(node => node.id);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleExpandCollapseAll = () => {
		const rootSectionIds = getRootSections();

		if (isAllExpanded) {
			toggleAllItems(expandedList.filter(id => !rootSectionIds.includes(id)));
		} else {
			const newExpandedList = [...new Set([...expandedList, ...rootSectionIds])];
			toggleAllItems(newExpandedList);
		}
		setIsAllExpanded(!isAllExpanded);
	};

	useEffect(() => {
		const rootSectionIds = getRootSections();
		const allExpanded =
			rootSectionIds.length > 0 && rootSectionIds.every(id => expandedList.includes(id));
		setIsAllExpanded(allExpanded);
	}, [expandedList, allFormNodes, allFormTemplateNodes]);

	return (
		<Container>
			{matches && (
				<BackButton
					url={`/projects/${projectId}`}
					buttonText="Back to Project"
					noMargin
				/>
			)}
			{isTemplate ? (
				<>
					<HeaderTitleContainer>
						<HeaderTitle>{templateName}</HeaderTitle>
						<IconsContainer>
							<YellowButton
								aria-describedby={id}
								variant="contained"
								onClick={handleClick}>
								Assign Template
							</YellowButton>
							<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
								<CustomMenuItem
									onClick={() => {
										setAssignType('project');
										handleAssignDialog();
										handleClose();
									}}>
									<MenuItemContent
										title="Assign to Project(s)"
										startIcon={<Business color="primary" />}
									/>
								</CustomMenuItem>
								<CustomMenuItem
									onClick={() => {
										setAssignType('team');
										handleAssignDialog();
										handleClose();
									}}>
									<MenuItemContent
										title="Assign to Team(s)"
										startIcon={<Group color="primary" />}
									/>
								</CustomMenuItem>
							</CustomMenu>
							<StyledIconButton onClick={handleDialog}>
								<NoteAddIcon color="primary" />
							</StyledIconButton>
							<FormDialog open={isDialogOpen} handleClose={handleDialog} />
							<AssignDialog
								open={isAssignDialogOpen}
								type={assignType}
								handleClose={handleAssignDialog}
							/>
						</IconsContainer>
					</HeaderTitleContainer>
					<Title>Report Content</Title>
					<Description>
						Add blocks to create sections and inputs for this report.
					</Description>
				</>
			) : (
				<>
					<HeaderTitleContainer>
						<HeaderTitle>{utilityForm?.formName}</HeaderTitle>
						<IconsContainer>
							{utilityForm?.locked && (
								<Tooltip title="The form cannot be submitted while uploading photos">
									<Lock color="primary" />
								</Tooltip>
							)}
							<SwitchForm />
							<Tools />
							<Download />
							{!!utilityForm && !!utilityForm.downloadUrlVirtual && (
								<PDF link={utilityForm.downloadUrlVirtual} />
							)}
							<Submit />
						</IconsContainer>
					</HeaderTitleContainer>
					<ViewModeContainer>
						{isViewMode && (
							<ViewModeToggle
								variant="contained"
								color="primary"
								onClick={handleExpandCollapseAll}
								startIcon={isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}>
								{isAllExpanded ? 'Collapse All' : 'Expand All'}
							</ViewModeToggle>
						)}

						<ViewModeToggle
							variant="contained"
							color="primary"
							onClick={toggleViewMode}
							startIcon={isViewMode ? <Edit /> : <Visibility />}>
							{isViewMode ? 'Full view' : 'Condensed View'}
						</ViewModeToggle>
					</ViewModeContainer>

					{!isViewMode && (
						<>
							<Title>Cover images</Title>
							<Description>
								Add images to be displayed on the cover page of the analytics generated
								from this report.
							</Description>
							<ImagesContainer>
								<CoverImage hasLogo />
							</ImagesContainer>
							<Title>Report Content</Title>
							<Description>
								Add blocks to create sections and inputs for this report.
							</Description>
						</>
					)}
				</>
			)}
		</Container>
	);
};

export default FormHeader;
