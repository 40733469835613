import { ListItemMenuPropsItem } from '../../reusable-components/List/ListItemMenu/ListItemMenu';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';

type TemplateListItemProps = {
	template: FormTemplate;
	renameTemplate: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	duplicateTemplate: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	deleteTemplate: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const useMenuItems = (props: TemplateListItemProps) => {
	const { renameTemplate, duplicateTemplate, deleteTemplate, template } = props;

	const menuItems: ListItemMenuPropsItem[] = [
		{
			title: 'Rename',
			onClick: renameTemplate,
			icon: <Edit color="primary" fontSize="small" />,
		},
		{
			title: 'Duplicate',
			onClick: duplicateTemplate,
			icon: <ContentCopy color="primary" fontSize="small" />,
		},
		{
			title: 'Delete',
			onClick: deleteTemplate,
			icon: <Delete color="primary" fontSize="small" />,
		},
	];

	const created = `${template.createdAt.toDate().toLocaleDateString()},
						${template.createdAt.toDate().toLocaleTimeString()}`;
	const updated = `${template.updatedAt.toDate().toLocaleDateString()},
						${template.updatedAt.toDate().toLocaleTimeString()} by ${template.updatedBy.name}`;

	return { menuItems, created, updated };
};

export default useMenuItems;
