import { FC, memo, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { PhotoContainer, PhotoLoadingIcon } from '../FormFillV2.styles';
import { PhotoV2 } from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import theme from '../../../../styles/theme';
import Loading from '../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import { getFlagColor } from '../../FormNodeWrapper/buttons/FlagButton';
import { styled } from '@mui/system';

interface SortablePhotoProps {
	photo: PhotoV2;
	index: number;
	isViewMode?: boolean;
}

const SortablePhoto: FC<SortablePhotoProps & { openGallery: (index: number) => void }> =
	memo(({ photo, index, openGallery, isViewMode }) => {
		const [isDragging, setIsDragging] = useState(false);
		const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
			id: photo.name,
		});

		const handleMouseDown = () => {
			setIsDragging(false); // Assume dragging starts as false
		};

		const handleMouseUp = () => {
			if (!isDragging) {
				openGallery(index); //If not dragging, on click, open gallery dialog.
			}
		};

		const handleDragStart = () => {
			setIsDragging(true); // Set dragging to true when drag starts
		};

		const style = {
			transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
			transition,
		};

		const combinedListeners = {
			...listeners,
			onClick: () => openGallery(index),
		};

		return (
			<PhotoContainer
				ref={setNodeRef}
				style={style}
				{...attributes}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onDragStart={handleDragStart}
				isViewMode={isViewMode}
				{...combinedListeners}>
				{!photo.url ? (
					<PhotoLoadingIcon isViewMode={isViewMode}>
						<Loading />
					</PhotoLoadingIcon>
				) : (
					<Thumbnail
						src={`${process.env.REACT_APP_image_resizer_url}?imageUrl=${photo.url}&width=100&height=100`}
						flag={photo.flag}
					/>
				)}
			</PhotoContainer>
		);
	});

export default SortablePhoto;

const Thumbnail = styled('img')<{ flag?: string; isViewMode?: boolean }>`
	width: ${({ isViewMode }) => (isViewMode ? '25px' : '100px')};
	height: ${({ isViewMode }) => (isViewMode ? '25px' : '100px')};
	object-fit: cover;
	border-radius: 5px;
	border: 1px solid ${({ flag }) => getFlagColor(flag, theme.palette.grey[800])};
	cursor: pointer;

	&:hover {
		border: 1px solid ${theme.palette.primary.dark};
	}
`;
