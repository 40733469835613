import { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { debounce } from 'lodash';
import { updateSingleProjectField } from '../../../../../../firebase/firestore/projects';
import { YellowTextField } from '../../../../../styled-components/styledReactTable';
import { ProjectData } from '../../../../../../types';
import { StatusTableContext } from '../../../StatusTableProvider';

const multilineFields = ['modelingScope', 'description'];

export default function StringTextField({
	projectId,
	defaultValue,
	field,
	type = 'string',
}: {
	projectId: string;
	defaultValue: string;
	field: keyof ProjectData;
	type?: React.HTMLInputTypeAttribute;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const [inputValue, setInputValue] = useState(defaultValue);

	const isMultiline = multilineFields.includes(field);
	const [hasLineBreaks, setHasLineBreaks] = useState(false);
	const textareaRef = useRef<HTMLTextAreaElement>();

	//check to see if the field is an array so we can handle it differently
	const isArrayField = ['clients'].includes(field);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
		if (isArrayField) {
			updateField(e.target.value.split(', ').map(item => item.trim()));
		} else {
			updateField(e.target.value);
		}
	};

	// useEffect for textareas that calculate whether or not the textarea has
	// any line breaks after any textarea value is changed
	useEffect(() => {
		if (textareaRef.current) {
			const sh = textareaRef.current.scrollHeight;
			if (sh > 44 && textareaRef.current.value) setHasLineBreaks(true);
			else setHasLineBreaks(false);
		}
	}, [inputValue]);

	/**
	 * Need to memoize the debounced function b/c of the textarea useEffect that
	 * monitors line breaks which calls setHasLineBreaks, causing a re-render
	 * which also re-renders this function and breaks the debouncing.
	 */
	const updateField = useMemo(
		() =>
			debounce(async (value: string | string[]) => {
				if (isArrayField && typeof value === 'string') {
					// If it's an array field and the value is a string, split into an array.
					value = value.split(',').map(item => item.trim());
				}

				if (value !== defaultValue) {
					setSnackbarProps({
						open: true,
						severity: 'warning',
						message: 'Saving changes...',
						hideDuration: null,
					});

					await updateSingleProjectField(projectId, value, field);
					setStatusProjects(prev => {
						return prev.map(proj => {
							if (proj.id === projectId) {
								return {
									...proj,
									[field]: value,
								};
							} else return proj;
						});
					});

					setSnackbarProps({
						open: true,
						severity: 'success',
						message: 'Changes saved!',
					});
				}
			}, 1000),
		[defaultValue, field, projectId, setStatusProjects, setSnackbarProps, isArrayField]
	);

	useEffect(() => {
		if (inputValue !== defaultValue) updateField(inputValue);
	}, [defaultValue, inputValue, updateField]);

	return (
		<YellowTextField
			fullWidth
			value={inputValue}
			onChange={handleInputChange}
			onBlur={() =>
				isArrayField
					? updateField(inputValue.split(', ').map(item => item.trim()))
					: updateField(inputValue)
			}
			type={type}
			multiline={isMultiline}
			maxRows={2}
			inputRef={isMultiline ? textareaRef : undefined}
			$hasLineBreaks={isMultiline ? hasLineBreaks : undefined}
			onClick={e => e.stopPropagation()}
		/>
	);
}
