import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import styled from 'styled-components';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from '../../../../../../../styles/theme';
import { useContext, useState } from 'react';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import dayjs, { Dayjs } from 'dayjs';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { MenuOption } from '../../../../../../form-components/FormHeader/buttons/components';
import { YellowTextField } from '../../../../../../styled-components/styledReactTable';

export default function StartTimeCell({
	row,
	projectData,
	rowEditing,
}: {
	row: Row<ProjectData>;
	projectData: ProjectData;
	rowEditing: boolean;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const projectId = projectData.id || '';

	const startTime = projectData.startTime;
	const displayStr = startTime ? startTime.time + ' ' + startTime.timezone : '-';
	const initDate = startTime ? dayjs(displayStr, 'hh:mm:ss a') : null;

	const [dateValue, setDateValue] = useState<Dayjs | null>(initDate);
	const [tz, setTz] = useState(startTime?.timezone || '');

	const handleChangeTime = async (value: unknown) => {
		if (typeof value === 'object' && value && '$d' in value) {
			const date = value.$d as Date;
			const timeString = date.toLocaleTimeString('en-US');
			const isoString = date.toISOString();

			setDateValue(dayjs(isoString));
			const newValue = { time: timeString, timezone: tz };

			if (timeString !== startTime?.time) {
				setSnackbarProps({
					open: true,
					severity: 'warning',
					message: 'Saving changes...',
					hideDuration: null,
				});

				await updateSingleProjectField(projectId, newValue, 'startTime');

				setStatusProjects(prev => {
					return prev.map(proj => {
						if (proj.id === projectId) {
							return {
								...proj,
								startTime: newValue,
							};
						} else return proj;
					});
				});

				setSnackbarProps({
					open: true,
					severity: 'success',
					message: 'Changes saved!',
				});
			}
		}
	};

	const tzOptions = ['EST', 'CST', 'PST'];
	const handleTzChange = async (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const newTz = event.target.value;
		setTz(newTz);

		if (newTz !== startTime?.timezone) {
			const timeString = dateValue?.toDate().toLocaleTimeString('en-US') || '';
			const newValue = { time: timeString, timezone: newTz };

			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'Saving changes...',
				hideDuration: null,
			});

			await updateSingleProjectField(projectId, newValue, 'startTime');

			setStatusProjects(prev => {
				return prev.map(proj => {
					if (proj.id === projectId) {
						return {
							...proj,
							startTime: newValue,
						};
					} else return proj;
				});
			});

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Changes saved!',
			});
		}
	};

	return !rowEditing ? (
		<span>{displayStr}</span>
	) : (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Container onClick={e => e.stopPropagation()}>
				<StyledTimePicker
					onChange={handleChangeTime}
					value={dateValue}
					views={['hours', 'minutes']}
				/>

				<TzPicker select value={tz} onChange={handleTzChange}>
					{tzOptions.map(option => (
						<MenuOption key={option} value={option}>
							{option}
						</MenuOption>
					))}
				</TzPicker>
			</Container>
		</LocalizationProvider>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const StyledTimePicker = styled(TimePicker)`
	flex-grow: 1;

	.MuiInputBase-root {
		color: ${theme.palette.primary.main};
		border: 1px solid ${theme.palette.primary.main};
	}

	.MuiButtonBase-root {
		color: ${theme.palette.primary.main};
	}
`;

const TzPicker = styled(YellowTextField)`
	width: 75px;
	flex-shrink: 0;
`;
