import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { YellowTextField } from '../../../components/styled-components/styledReactTable';
import { useContext } from 'react';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import { GalleryNode, GalleryPhoto } from '../types';

type SelectorProps = {
	title: string;
	options: GalleryNode[] | GalleryPhoto[];
	value: number;
};

export default function Selector({ title, options, value }: SelectorProps) {
	const { setGalleryIndex } = useContext(V2FormGalleryContext);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setGalleryIndex(Number(e.target.value));
	};

	return (
		<Container
			select
			fullWidth
			label={title}
			onChange={handleChange}
			value={value}
			InputLabelProps={{ shrink: true }}>
			{options.map(option => {
				if ('type' in option) {
					// 'option' is a GalleryNode.
					return (
						<MenuItem value={option.galleryIndex} key={option.id}>
							{option.displayTitle}
						</MenuItem>
					);
				} else {
					// 'option' is a GalleryPhoto.
					return (
						<MenuItem value={option.galleryIndex} key={option.name}>
							{option.originalName}
						</MenuItem>
					);
				}
			})}
		</Container>
	);
}

const Container = styled(YellowTextField)`
	label {
		color: #ffb310;
	}
`;
