import { Row } from 'react-table';
import { ProjectData, ProjectTravel } from '../../../../../../../types';
import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkIcon from '@mui/icons-material/Work';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import Spinner from '../../../../../Spinner';

export default function TravelCell({ row }: { row: Row<ProjectData> }) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { traveledProjects } = useContext(ProjectContext);

	const [loading, setLoading] = useState(false);

	const projectData = row.original;
	const projectId = projectData.id;
	const projectTraveled = traveledProjects.find(
		projectTravel => projectTravel.projectId === projectId
	);

	const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setLoading(true);
		try {
			if (projectTraveled) {
				const flagDoc = doc(db, `project_travel/${projectTraveled.id}`);
				await deleteDoc(flagDoc);
				setSnackbarProps({
					open: true,
					message: `${row.original.address} un-traveled!`,
					severity: 'success',
				});
			} else {
				const newFlagDoc = doc(collection(db, 'project_travel'));
				const newFlag: ProjectTravel = {
					id: newFlagDoc.id,
					projectId: row.original.id || '',
					flaggerName: user?.fullName || '',
					flaggerEmail: user?.email || '',
					flaggerId: user?.id || '',
					timestamp: new Date(),
				};
				await setDoc(newFlagDoc, newFlag);
				setSnackbarProps({
					open: true,
					message: `${row.original.address} traveled!`,
					severity: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				message: 'Could not edit project travel status',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return !loading ? (
		<IconButton onClick={handleClick} disabled={!user?.isAdmin}>
			{!projectTraveled ? <NotTraveled /> : <Traveled />}
		</IconButton>
	) : (
		<Spinner />
	);
}

const NotTraveled = styled(WorkOutlineIcon)`
	fill: white;
	transition: 0.2s;

	&:hover {
		fill: ${theme.palette.primary.main};
	}
`;

const Traveled = styled(WorkIcon)`
	fill: ${theme.palette.primary.main};
	transition: 0.2s;

	&:hover {
		fill: white;
	}
`;
